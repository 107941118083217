import {NgModule} from "@angular/core";
import {CoreModule} from "./core/core.module";
import {SharedModule} from "./shared/shared.module";
import {LayoutModule} from "./layout/layout.module";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {LocationStrategy, HashLocationStrategy} from "@angular/common";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import {
  Config, ConfigService, ErrorInterceptor,
  HRMPWAApiModule,
  INITIALIZE_API_URL,
  INITIALIZE_RFC_URL, JwtInterceptor,
  mergeConfigs,
  provideConfig,
} from "@hrm-pwa/hrm-pwa-api";
import {ServiceWorkerModule} from "@angular/service-worker";
import {environment} from "../environments/environment";
import {MAT_DATE_LOCALE} from "@angular/material/core";

export const config: Config = mergeConfigs(environment);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HRMPWAApiModule.forRoot(),
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // core & shared
    CoreModule,
    SharedModule,
    LayoutModule,
  ],
  providers: [
    provideConfig(config, [ConfigService]),
    INITIALIZE_API_URL,
    INITIALIZE_RFC_URL,
    {provide: MAT_DATE_LOCALE, useValue: config.locale ?? 'en-US'},
    // https://angular.io/api/common/LocationStrategy
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
