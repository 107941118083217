<ng-container *ngIf="display">
  <button *ngIf="!raised; else raisedButton" mat-button (click)="interact()" [matTooltip]="tooltip | translate"
          [disabled]="disabled">
    <mat-icon class="{{cls}}">add</mat-icon>
  </button>
  <ng-template #raisedButton>
    <button mat-raised-button color="primary" class="{{cls}}" (click)="interact()" [matTooltip]="tooltip | translate"
            [disabled]="disabled" tabindex="-1">
      {{tooltip | translate}}
    </button>
  </ng-template>
</ng-container>
