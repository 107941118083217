import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from "@angular/core";
import {ACLInterAction, ApiService, Bean, canDo, UnsubscribeOnDestroyDirectiveAdapter} from "@hrm-pwa/hrm-pwa-api";

@Component({
  selector: "base-delete",
  templateUrl: "./base-delete.component.html",
  styleUrls: ["./base-delete.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDeleteComponent<T extends Bean> extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit {
  private _m = '';

  @Input()
  public set module(module: string) {
    this._m = module;
    this.checkInteraction();
  }

  private _allow = false;

  public get allow(): boolean {
    return this._allow;
  }

  public get record(): T {
    return this.data.record;
  }

  constructor(
    public dialogRef: MatDialogRef<BaseDeleteComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public beanService: ApiService<T>,
  ) {
    super();
    this.module = data.module;
  }

  public ngOnInit(): void {

  }

  public checkInteraction(): void {
    this._allow = canDo(ACLInterAction.DELETE, this._m, this.record);
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmDelete(): void {
    this.beanService.deleteRecord(this.record.id);
  }
}
