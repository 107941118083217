export const environment = {
  name: 'ESA DEMO',
  apiVersion: '8',
  assetsBaseUrl: 'assets',
  authConfig: {
    client_id: '465bc0ef-4bd7-e583-1870-627d43babdc9',
    client_secret: 'admin',
    redirect_uri: `${window.location.origin}${window.location.pathname}`,
  },
  rfcEndpoint: 'service/v4_1/rest.php',
  restEndpoint: 'Api',
  production: true,
  serviceWorker: true,
  crmBaseUrl: "https://pwa-demo.cm-sugar.at",
  logoutUrl: "/",
  pageSize: 10,
  locale: 'de-DE',
};
