import {Injectable} from '@angular/core';
import MobileDetect from 'mobile-detect';
import {UnsubscribeOnDestroyServiceAdapter} from "../utils/adapters/UnsubscribeOnDestroyServiceAdapter";

@Injectable({
  providedIn: 'root',
})
export class DeviceService extends UnsubscribeOnDestroyServiceAdapter {
  public readonly isIos: boolean;
  public readonly isSafari: boolean;
  public readonly isChrome: boolean;
  public readonly isFirefox: boolean;
  public readonly isIpadSafari: boolean;

  private md: MobileDetect;

  /* istanbul ignore next */
  constructor() {
    super();
    this.md = new MobileDetect(window.navigator.userAgent);

    this.isSafari = this.md.userAgent() === 'Safari';
    this.isIos = this.md.os() === 'iOS';
    this.isFirefox = this.md.userAgent() === 'Firefox';
    this.isChrome = this.md.userAgent() === 'Chrome';
    if (this.isSafari) {
      // watch: https://github.com/hgoebl/mobile-detect.js/blob/master/mobile-detect.js
      this.isIpadSafari = this.md.tablet() === 'iPad';
      if (!this.isIpadSafari) {
        // Since iOS 13 the safari on iPad always pretends to be the macOS version of safari.
        // The only distinction between them are currently the amount of touch points.
        // watch: https://developer.mozilla.org/de/docs/Web/API/Navigator
        this.isIpadSafari = 'standalone' in navigator && window.navigator.maxTouchPoints > 1;
      }
    } else {
      this.isIpadSafari = false;
    }
  }

  public isMobile(): boolean {
    return !!this.md.mobile() && !this.isTablet();
  }

  public isTablet(): boolean {
    return !!this.md.tablet();
  }
}
