<div class="container" *ngIf="!hidden">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 ms-2 me-2 mb-2 font-17">
            {{dialogTitle}}
            <button mat-button (click)="accordion.openAll()">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <button mat-button (click)="accordion.closeAll()">
              <mat-icon>arrow_drop_up</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
        <div class="example-button-row">
          <acl-edit-button [module]="beanService.module" [record]="record" (clicked)="edit()"></acl-edit-button>
          <acl-delete-button [module]="beanService.module" (clicked)="delete()"></acl-delete-button>
        </div>
      </div>
    </div>
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'MAIN.BASE_PANEL' | translate}}
          </mat-panel-title>
          <mat-panel-description class="right-aligned">
            <mat-icon>info</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-grid-list cols="2" rowHeight="50">
          <mat-grid-tile>{{'MAIN.NAME' | translate}}</mat-grid-tile>
          <mat-grid-tile>{{record.name}}</mat-grid-tile>
          <mat-grid-tile>{{'MAIN.DATE_ENTERED' | translate}}</mat-grid-tile>
          <mat-grid-tile>{{record.date_entered | dateFormat}}</mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'MAIN.DESCRIPTION_PANEL' | translate}}
          </mat-panel-title>
          <mat-panel-description class="right-aligned">
            <mat-icon>description</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-grid-list cols="2">
          <mat-grid-tile>{{'MAIN.DESCRIPTION' | translate}}</mat-grid-tile>
          <mat-grid-tile>{{record.description}}</mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
        <div class="example-button-row">
          <acl-edit-button [module]="beanService.module" [record]="record" (clicked)="edit()"></acl-edit-button>
          <acl-delete-button [module]="beanService.module" (clicked)="delete()"></acl-delete-button>
        </div>
      </div>
    </div>
  </div>
</div>
