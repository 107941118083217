import {NgModule} from "@angular/core";
import {ComponentsModule} from "./components/components.module";
import {MaterialModule} from "./material.module";
import {DirectivesModule} from "./directives/directives.module";

const MODULES = [
  MaterialModule,
  ComponentsModule,
  DirectivesModule,
];

@NgModule({
  declarations: [],
  imports: [
    ...MODULES,
  ],
  exports: [
    ...MODULES,
  ],
})
export class SharedModule {
}
