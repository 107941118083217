import {Injectable} from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import {canDo, isEmpty} from "../utils/utils";
import {AuthService} from "../services/auth.service";
import {ACLInterAction} from "../models/enums";

@Injectable({
  providedIn: "root",
})
export class ACLGuard implements CanActivate {
  constructor(private authService: AuthService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.authService.currentUserValue;
    if (isEmpty(user)) {
      return false;
    }
    return canDo(ACLInterAction.ACCESS, route.data.module);
  }
}
