import {DOCUMENT} from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  AfterViewInit,
  Renderer2,
  ChangeDetectionStrategy,
} from "@angular/core";
import {RightSidebarService} from "../../core/service/rightsidebar.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {DirectionService} from "../../core/service/direction.service";
import {UnsubscribeOnDestroyDirectiveAdapter, ConfigService} from "@hrm-pwa/hrm-pwa-api";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-right-sidebar",
  templateUrl: "./right-sidebar.component.html",
  styleUrls: ["./right-sidebar.component.sass"],
})
export class RightSidebarComponent extends UnsubscribeOnDestroyDirectiveAdapter
  implements OnInit, AfterViewInit {
  selectedBgColor = "white";
  maxHeight: string;
  maxWidth: string;
  isOpenSidebar: boolean;
  isDarkSidebar = false;
  isDarTheme = false;
  isRtl = false;
  public config: any = {};

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private directionService: DirectionService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.config = this.configService.configData;
    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
      (isRunning) => {
        this.isOpenSidebar = isRunning;
      }
    );
    this.setRightSidebarWindowHeight();
  }

  public ngAfterViewInit(): void {
    // set header color on startup
    if (localStorage.getItem("choose_skin")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_skin") ?? ''
      );
      this.selectedBgColor = localStorage.getItem("choose_skin_active") ?? '';
    } else {
      this.renderer.addClass(
        this.document.body,
        "theme-" + this.config.theme_color
      );
      this.selectedBgColor = this.config.theme_color;
    }

    if (localStorage.getItem("menuOption")) {
      if (localStorage.getItem("menuOption") === "menu_dark") {
        this.isDarkSidebar = true;
      } else if (localStorage.getItem("menuOption") === "menu_light") {
        this.isDarkSidebar = false;
      } else {
        this.isDarkSidebar =
          this.config.sidebar.backgroundColor === "dark" ? true : false;
      }
    } else {
      this.isDarkSidebar =
        this.config.sidebar.backgroundColor === "dark" ? true : false;
    }

    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") === "dark") {
        this.isDarTheme = true;
      } else if (localStorage.getItem("theme") === "light") {
        this.isDarTheme = false;
      } else {
        this.isDarTheme = this.config.variant === "dark" ? true : false;
      }
    } else {
      this.isDarTheme = this.config.variant === "dark" ? true : false;
    }

    if (localStorage.getItem("isRtl")) {
      if (localStorage.getItem("isRtl") === "true") {
        this.setRTLSettings();
      } else if (localStorage.getItem("isRtl") === "false") {
        this.setLTRSettings();
      }
    } else {
      if (this.config.rtl == true) {
        this.setRTLSettings();
      } else {
        this.setLTRSettings();
      }
    }
  }

  public lightSidebarBtnClick(): void {
    this.renderer.removeClass(this.document.body, "menu_dark");
    this.renderer.removeClass(this.document.body, "logo-black");
    this.renderer.addClass(this.document.body, "menu_light");
    this.renderer.addClass(this.document.body, "logo-white");
    const menuOption = "menu_light";
    localStorage.setItem("choose_logoheader", "logo-white");
    localStorage.setItem("menuOption", menuOption);
  }

  public darkSidebarBtnClick(): void {
    this.renderer.removeClass(this.document.body, "menu_light");
    this.renderer.removeClass(this.document.body, "logo-white");
    this.renderer.addClass(this.document.body, "menu_dark");
    this.renderer.addClass(this.document.body, "logo-black");
    const menuOption = "menu_dark";
    localStorage.setItem("choose_logoheader", "logo-black");
    localStorage.setItem("menuOption", menuOption);
  }

  public lightThemeBtnClick(): void {
    this.renderer.removeClass(this.document.body, "dark");
    this.renderer.removeClass(this.document.body, "submenu-closed");
    this.renderer.removeClass(this.document.body, "menu_dark");
    this.renderer.removeClass(this.document.body, "logo-black");
    if (localStorage.getItem("choose_skin")) {
      this.renderer.removeClass(
        this.document.body,
        localStorage.getItem("choose_skin") ?? '',
      );
    } else {
      this.renderer.removeClass(
        this.document.body,
        "theme-" + this.config.theme_color
      );
    }

    this.renderer.addClass(this.document.body, "light");
    this.renderer.addClass(this.document.body, "submenu-closed");
    this.renderer.addClass(this.document.body, "menu_light");
    this.renderer.addClass(this.document.body, "logo-white");
    this.renderer.addClass(this.document.body, "theme-white");
    const theme = "light";
    const menuOption = "menu_light";
    this.selectedBgColor = "white";
    this.isDarkSidebar = false;
    localStorage.setItem("choose_logoheader", "logo-white");
    localStorage.setItem("choose_skin", "theme-white");
    localStorage.setItem("theme", theme);
    localStorage.setItem("menuOption", menuOption);
  }

  public darkThemeBtnClick(): void {
    this.renderer.removeClass(this.document.body, "light");
    this.renderer.removeClass(this.document.body, "submenu-closed");
    this.renderer.removeClass(this.document.body, "menu_light");
    this.renderer.removeClass(this.document.body, "logo-white");
    if (localStorage.getItem("choose_skin")) {
      this.renderer.removeClass(
        this.document.body,
        localStorage.getItem("choose_skin") ?? '',
      );
    } else {
      this.renderer.removeClass(
        this.document.body,
        "theme-" + this.config.theme_color
      );
    }
    this.renderer.addClass(this.document.body, "dark");
    this.renderer.addClass(this.document.body, "submenu-closed");
    this.renderer.addClass(this.document.body, "menu_dark");
    this.renderer.addClass(this.document.body, "logo-black");
    this.renderer.addClass(this.document.body, "theme-black");
    const theme = "dark";
    const menuOption = "menu_dark";
    this.selectedBgColor = "black";
    this.isDarkSidebar = true;
    localStorage.setItem("choose_logoheader", "logo-black");
    localStorage.setItem("choose_skin", "theme-black");
    localStorage.setItem("theme", theme);
    localStorage.setItem("menuOption", menuOption);
  }

  public setRightSidebarWindowHeight(): void {
    const height = window.innerHeight - 60;
    this.maxHeight = height + "";
    this.maxWidth = "500px";
  }

  public toggleRightSidebar(): void {
    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );
  }

  public switchDirection(event: MatSlideToggleChange): void {
    const isRtl: string = String(event.checked);
    if (
      isRtl === "false" &&
      document.getElementsByTagName("html")[0].hasAttribute("dir")
    ) {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      this.renderer.removeClass(this.document.body, "rtl");
      this.directionService.updateDirection("ltr");
    } else if (
      isRtl === "true" &&
      !document.getElementsByTagName("html")[0].hasAttribute("dir")
    ) {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      this.renderer.addClass(this.document.body, "rtl");
      this.directionService.updateDirection("rtl");
    }
    localStorage.setItem("isRtl", isRtl);
    this.isRtl = event.checked;
  }

  public setRTLSettings(): void {
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    this.renderer.addClass(this.document.body, "rtl");
    this.isRtl = true;
    localStorage.setItem("isRtl", "true");
  }

  public setLTRSettings(): void {
    document.getElementsByTagName("html")[0].removeAttribute("dir");
    this.renderer.removeClass(this.document.body, "rtl");
    this.isRtl = false;
    localStorage.setItem("isRtl", "false");
  }
}
