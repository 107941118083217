import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AttachedFile, UnsubscribeOnDestroyDirectiveAdapter} from "@hrm-pwa/hrm-pwa-api";

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit {

  @Input() public label = 'MAIN.CHOOSE_FILE';
  @Input() public fileAttr = 'MAIN.CHOOSE_FILE';
  @Output() public uploadedFile: EventEmitter<Promise<AttachedFile>> = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor() {
    super();
  }

  public ngOnInit() {
  }

  public uploadFileEvt(e: Event): void {
    const files = (e.target as HTMLInputElement).files;
    if (!files) {
      this.fileAttr = 'MAIN.CHOOSE_FILE';
      return;
    }
    const file2Base64 = (file: File): Promise<AttachedFile> => {
      return new Promise<AttachedFile>((resolve, reject) => {
        // HTML5 FileReader API
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target.result) {
            let f = {
              filename: file.name,
              length: file.size,
              mimeType: file.type,
              file,
            } as AttachedFile;
            resolve(f);
          }
          return null;
        }
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);

        const asImage = (e) => {
          let image = new Image();
          image.src = e.target.result;
          image.onload = (rs) => {
            let imgBase64Path = e.target.result;
          };
        };
      });
    }
    Array.from(files).forEach((file) => {
      if (file) {
        this.fileAttr = file.name;
        this.uploadedFile.emit(file2Base64(file));
      }
    });
  }
}
