<section class="content">
  <div class="content-block">
    <div class="block-header">
      <!-- breadcrumb -->
      <app-breadcrumb [title]="'MAIN.MODULE_NAME' | translate" [items]="[]"
                      [active_item]="'MAIN.MODULE_NAME' | translate">
      </app-breadcrumb>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="body">
            <div class="responsive_table">
              <div class="materialTableHeader">
                <div class="row">
                  <div class="col-12">
                    <ul class="header-buttons-left ml-0">
                      <li class="dropdown mb-2" style="width:100%">
                        <input placeholder="{{'MAIN.SEARCH' | translate}}" type="text" #filter
                               class="search-field"
                               aria-label="Search box"/>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <ul class="header-buttons-left ml-0">
                      <li class="dropdown">
                        <div class="example-button-row m-l-10">
                          <acl-add-button [module]="beanService.module" (clicked)="addNew()"></acl-add-button>
                          <acl-refresh-button [module]="beanService.module" (clicked)="reset()"></acl-refresh-button>
                          <acl-delete-button [module]="beanService.module" (clicked)="removeSelectedRows()"
                                             [hidden]=!selection.hasValue()></acl-delete-button>
                          <ng-container *ngIf="currentUser.role === 'Admin'">
                            <button mat-button color="primary" [matMenuTriggerFor]="belowMenu" matTooltip="EXPORT">
                              <mat-icon>save_alt</mat-icon>
                            </button>
                            <mat-menu #belowMenu="matMenu" yPosition="below">
                              <button mat-menu-item class="export-button" matTooltip="XLSX">
                                <img src="assets/images/icons/xlsx.png"
                                     (click)="exporter.exportTable('xlsx', {fileName:'list', sheet: 'sheet1'})"/>
                                <mat-label> .XLSX</mat-label>
                              </button>
                              <button mat-menu-item class="export-button" matTooltip="CSV">
                                <img src="assets/images/icons/csv.png" (click)="exporter.exportTable('csv')"/>
                                <mat-label> .CSV</mat-label>
                              </button>
                              <button mat-menu-item class="export-button" matTooltip="JSON">
                                <img src="assets/images/icons/json.png" (click)="exporter.exportTable('json')"/>
                                <mat-label> .JSON</mat-label>
                              </button>
                              <button mat-menu-item class="export-button" matTooltip="TXT">
                                <img src="assets/images/icons/txt.png" (click)="exporter.exportTable('txt')"/>
                                <mat-label> .TXT</mat-label>
                              </button>
                            </mat-menu>
                          </ng-container>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <mat-table #table matTableExporter [dataSource]="dataSource" matSort [matSortActive]="defaultSort"
                         [matSortDirection]="defaultSortDirection" class="mat-cell" #exporter="matTableExporter">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                  <mat-header-cell *matHeaderCellDef [ngClass]="'tbl-col-width-per-5'">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                                  [ngClass]="'tbl-checkbox'">
                    </mat-checkbox>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" [ngClass]="'tbl-col-width-per-5'">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null"
                                  [checked]="selection.isSelected(row)" [ngClass]="'tbl-checkbox'">
                    </mat-checkbox>
                  </mat-cell>
                </ng-container>
                <!-- actions -->
                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef class="pr-0">Actions</mat-header-cell>
                  <mat-cell *matCellDef="let row;" class="pr-0">
                    <button mat-icon-button color="accent" (click)="$event.stopPropagation()"
                            (click)="editCall(row)" class="tbl-action-btn">
                      <app-feather-icons [icon]="'edit'" [class]="'tbl-fav-edit'">
                      </app-feather-icons>
                    </button>
                    <button mat-icon-button color="accent" (click)="$event.stopPropagation()"
                            (click)="deleteItem(row)" class="tbl-action-btn">
                      <app-feather-icons [icon]="'trash-2'" [class]="'tbl-fav-delete'">
                      </app-feather-icons>
                    </button>
                  </mat-cell>
                </ng-container>
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAIN.ID' | translate}}</mat-header-cell>
                  <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAIN.NAME' | translate}}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.name}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="date_entered">
                  <mat-header-cell *matHeaderCellDef
                                   mat-sort-header>{{'MAIN.DATE_ENTERED' | translate}}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.date_entered | dateFormat}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="description">
                  <mat-header-cell *matHeaderCellDef
                                   mat-sort-header>{{'MAIN.DESCRIPTION' | translate}}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{row.description}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="detailCall(row)"
                         [style.cursor]="'pointer'" matRipple>
                </mat-row>
              </mat-table>
              <!-- Loading spinner -->
              <div *ngIf="(beanService.isLoading | async)" class="tbl-spinner">
                <mat-progress-spinner color="primary" [diameter]="40" mode="indeterminate">
                </mat-progress-spinner>
              </div>
              <!-- context menu start -->
              <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
                   [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
              </div>
              <mat-menu #contextMenu="matMenu">
                <ng-template matMenuContent let-item="item">
                  <button mat-menu-item (click)="addNew()">
                    <mat-icon>add_box</mat-icon>
                    <span>Add Record</span>
                  </button>
                  <button mat-menu-item (click)="editCall(item)">
                    <mat-icon>create</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item (click)="deleteItem(item)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button>
                  <button mat-menu-item (click)="reset()">
                    <mat-icon>refresh</mat-icon>
                    <span>Reset</span>
                  </button>
                  <button mat-menu-item disabled>
                    <mat-icon>no_encryption</mat-icon>
                    <span>Disable</span>
                  </button>
                  <button mat-menu-item [matMenuTriggerFor]="nestedmenu">
                    <mat-icon>list_alt</mat-icon>
                    <span>More...</span>
                  </button>
                </ng-template>
              </mat-menu>
              <mat-menu #nestedmenu="matMenu">
                <button mat-menu-item>
                  <mat-icon>mail_outline</mat-icon>
                  <span>Item 1</span>
                </button>
                <button mat-menu-item>
                  <mat-icon>call</mat-icon>
                  <span>Item 2</span>
                </button>
                <button mat-menu-item>
                  <mat-icon>chat</mat-icon>
                  <span>Item 3</span>
                </button>
              </mat-menu>
              <!-- context menu end -->
              <div *ngIf="!(beanService.isLoading | async)" class="no-results"
                   [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'">
                {{'MAIN.NO_RESULTS' | translate}}
              </div>
              <mat-paginator #paginator [length]="total" [pageIndex]="0"
                             [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
