/**
 * replaces given string literal / template by the indexed parameters and given order
 * placeholders must be "{[0-9]+}, escaping of placeholder is possible by double {{[0-9]+}}
 * formatString("Hello, {0}! The answer is {1}", "World", 42); => Hello, World! The answer is 42
 * formatString("{0} {1}", "{1}", 42); => {1} 42
 * formatString("{0} {1} {1} {2}", "{1}", 42); => {1} 42 42 {2}
 * formatString("{{0}} will be replaced with {0}", 42); => {0} will be replaced with 42
 * formatString("Values between {0} and {0}", 50, 300); => Values between 50 and 300
 * formatString("{0}} woops, throw!"); => ERROR invalid format string
 * @param fmt
 * @param args
 */
export const formatString = (fmt: string, ...args: unknown[]): string => {
  if (!fmt.match(/^(?:(?:(?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{[0-9]+\}))+$/)) {
    throw new Error('invalid format string');
  }
  return fmt.replace(
    /((?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{([0-9]+)\})/g,
    (matchOuter: string, str: string, index: string) => {
      if (str) {
        return str.replace(/(?:{{)|(?:}})/g, (matchInner: string) => matchInner[0]);
      }
      // index is the string identifier of the placeholder
      const i = parseInt(index, 10);
      if (i >= args.length) {
        return matchOuter;
      }
      return args[i] as string;
    },
  );
};

