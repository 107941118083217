import {FactoryProvider, InjectionToken} from '@angular/core';
import {ConfigService} from "./services/config.service";

export const CONFIG_TOKEN = new InjectionToken<Config>('config');

export interface AuthConfig {
  client_id: string,
  client_secret: string,
  redirect_uri: string,
}

export interface InConfiguration {
  rtl: boolean;
  variant: string;
  theme_color: string;
  logo_bg_color: string;
  sidebar: {
    collapsed: boolean;
    backgroundColor: string;
  };
}

declare global {
  namespace CRM_PWA {
    // merge configs into global `CRM_PWA.Config`
    interface Config {
      serviceWorker: boolean;
      production: boolean;
      assetsBaseUrl: string;
      name: string,
      apiVersion: string;
      authConfig: AuthConfig | undefined;
      crmBaseUrl: string,
      restEndpoint: string;
      rfcEndpoint: string,
      logoutUrl?: string;
      /** Url for the errorpage, the user is directed to on unhandled error - default will be /404-error */
      errorPageUrl?: string;
      layout?: InConfiguration;
      pageSize?: number;
      locale?: string;
    }
  }
}

// just for better convenience
export type Config = CRM_PWA.Config;
export type PartialConfig = Partial<CRM_PWA.Config>;
export type CheckConfig<C> = { [P in keyof C]: P extends keyof CRM_PWA.Config ? CRM_PWA.Config[P] : never };

export function provideConfig(
  config: Config,
  deps?: unknown[],
): FactoryProvider {
  return {
    deps,
    provide: CONFIG_TOKEN,
    useFactory: (configService: ConfigService) => ({
      ...config,
      layout: configService.configData,
    }),
  };
}

export function mergeConfigs<T extends PartialConfig>(config1: T & CheckConfig<T>): T;
export function mergeConfigs<T extends PartialConfig, U extends PartialConfig>(
  config1: T & CheckConfig<T>,
  config2: U & CheckConfig<U>,
): T & U;
export function mergeConfigs<T extends PartialConfig,
  U extends PartialConfig,
  V extends PartialConfig>(config1: T & CheckConfig<T>, config2: U & CheckConfig<U>, config3: V & CheckConfig<V>): T & U & V;
export function mergeConfigs<T extends PartialConfig,
  U extends PartialConfig,
  V extends PartialConfig,
  W extends PartialConfig>(
  config1: T & CheckConfig<T>,
  config2: U & CheckConfig<U>,
  config3: V & CheckConfig<V>,
  config4: W & CheckConfig<W>,
): T & U & V & W;
export function mergeConfigs(...configs: PartialConfig[]): PartialConfig {
  return Object.assign({}, ...configs);
}
