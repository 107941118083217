import {Pipe, PipeTransform} from '@angular/core';
import { NumberUtils } from '../utils/number-utils';
import {getCurrentUser, isEmptyString} from "../utils/utils";

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  public transform(value: number | string, precision: number = 2, symbol?: string, rightPosition?: boolean): string {
    const user = getCurrentUser();
    if (isEmptyString(value)) {
      value = 0;
    } else {
      if (typeof value === 'string') {
        value = NumberUtils.unformatNumber(value, user.user_decimal_separator, user.user_number_separator, precision);
      }
    }
    return NumberUtils.numberFormatFactory(value, user.user_decimal_separator, user.user_number_separator, precision, symbol, rightPosition);
  }
}
