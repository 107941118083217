import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";
import {
  ApiService,
  Bean, LanguageService,
  UnsubscribeOnDestroyDirectiveAdapter
} from "@hrm-pwa/hrm-pwa-api";
import {ComponentType} from "@angular/cdk/portal";
import {BaseEditComponent} from "../edit/base-edit.component";
import {BaseDeleteComponent} from "../delete/base-delete.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatAccordion} from "@angular/material/expansion";

@Component({
  selector: "base-detail",
  templateUrl: "./base-detail.component.html",
  styleUrls: ["./base-detail.component.sass"],
})
export class BaseDetailComponent<T extends Bean> extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit, AfterViewInit {
  public dialogTitle: string;
  public hidden = false;
  protected action: string;
  protected _record: T;

  public set record(record: T) {
    this._record = record;
  }

  public get record(): T {
    return this._record;
  }

  public editComponent: ComponentType<BaseEditComponent<T>> = BaseEditComponent;
  public deleteComponent: ComponentType<BaseDeleteComponent<T>> = BaseDeleteComponent;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    public dialogRef: MatDialogRef<BaseDetailComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public beanService: ApiService<T>,
    public dialog: MatDialog,
    protected snackBar: MatSnackBar,
    protected languageService: LanguageService,
  ) {
    super();
    // Set the defaults
    this.action = data.action;
    this.record = data.record;
    this.dialogTitle = this.record.name ?? this.languageService.getText('MAIN.DETAIL_TITLE');
  }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.expandAllPanels();
  }

  public edit(): void {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    this.hidden = true;
    const dialogRefEdit = this.dialog.open(this.editComponent, {
      data: {
        record: this.record,
        action: "edit",
      },
      direction: tempDirection,
    });
    this.subs.sink = dialogRefEdit.afterClosed().subscribe((record: T) => {
      this.hidden = false;
      if (record) {
        this.record = this.beanService.getRecord(this.record.id);
        this.dialogTitle = this.record.name ?? this.languageService.getText('MAIN.DETAIL_TITLE');
        this.showNotification(
          "black",
          this.languageService.getText('MAIN.EDIT_RECORD_SUCCESSFULLY'),
          "bottom",
          "center"
        );
      }
      this.expandAllPanels();
    });
  }

  public delete(): void {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRefDelete = this.dialog.open(this.deleteComponent, {
      width: "400px",
      data: {
        record: this.record,
        module: this.beanService.module,
      },
      direction: tempDirection,
    });
    this.subs.sink = dialogRefDelete.afterClosed().subscribe((result) => {
      if (result === 1) {
        this.dialogRef.close();
        this.showNotification(
          "snackbar-danger",
          this.languageService.getText('MAIN.DELETE_RECORD_SUCCESSFULLY'),
          "bottom",
          "center"
        );
      }
      this.expandAllPanels();
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public showNotification(colorName, text, placementFrom, placementAlign): void {
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  private expandAllPanels(): void {
    setTimeout(() => {
      this.accordion.openAll();
    }, 500);
  }
}
