import {CommonModule} from '@angular/common';
import {APP_INITIALIZER, ModuleWithProviders, NgModule, Type} from '@angular/core';
import {ApiService} from "./services/api.service";
import {DateTimeFormatPipe} from "./pipes/date-time.pipe";
import {DateFormatPipe} from "./pipes/date.pipe";
import {ConfigService} from "./services/config.service";
import {NumberFormatPipe} from "./pipes/number.pipe";
import {AuthService} from "./services/auth.service";
import {AuthGuard} from './guards/auth.guard';
import {ACLGuard} from './guards/acl.guard';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";
import {LanguageService} from "./services/language.service";
import {TranslatePipe} from "./pipes/translate.pipe";
import { HttpClient } from '@angular/common/http';

const DECLARATIONS: Array<Type<unknown>> = [
  DateFormatPipe,
  DateTimeFormatPipe,
  NumberFormatPipe,
  TranslatePipe,
];

const PROVIDERS: Array<Type<unknown>> = [
  ApiService,
  ConfigService,
  AuthService,
  AuthGuard,
  ACLGuard,
  LanguageService,
];

export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    {prefix: "./assets/i18n/", suffix: ".json"},
    {prefix: "./assets/i18n/enums/", suffix: ".enums.json"},
  ]);
}

/**
 * Factory function for the Angular FactoryProvider.
 * Requires an `init` method returning void or Promise<void>.
 */
export function initializeService(service: { init(): Promise<void> | void }) {
  return (): Promise<void> | void => service.init();
}

@NgModule({
  declarations: [...DECLARATIONS],
  exports: [
    ...DECLARATIONS,
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
})
export class HRMPWAApiModule {
  public static forRoot(): ModuleWithProviders<HRMPWAApiModule> {
    return {
      ngModule: HRMPWAApiModule,
      providers: [
        ...PROVIDERS,
        {
          provide: APP_INITIALIZER,
          useFactory: initializeService,
          deps: [LanguageService],
          multi: true
        }
      ],
    };
  }
}
