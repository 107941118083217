import {Inject, Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {isEmptyString} from "../utils/utils";
import {UnsubscribeOnDestroyServiceAdapter} from "../utils/adapters/UnsubscribeOnDestroyServiceAdapter";
import {Config, CONFIG_TOKEN} from "../config";

@Injectable({
  providedIn: "root",
})
export class LanguageService extends UnsubscribeOnDestroyServiceAdapter {
  public languages: string[] = ["en_us", "de_de"];
  public localizations = {};

  constructor(
    @Inject(CONFIG_TOKEN) protected config_token: Config,
    protected translate: TranslateService,
  ) {
    super();
    this.translate.addLangs(this.languages);
  }

  public setLanguage(lang: string): void {
    localStorage.setItem("lang", lang);
    // force reload to ensure the changes of all date and localized enum values by current user language
    location.reload();
  }

  public getText(key: string): string {
    return !isEmptyString(key) ? this.translate.instant(key) : '';
  }

  public getDefaultLanguage(): string {
    return this.config_token.locale.toLowerCase().replace('-', '_') ?? 'en_us';
  }

  public init(): void {
    const browserLang = localStorage.getItem("lang") ?? this.translate.getBrowserCultureLang().toLowerCase() ?? '';
    this.loadLanguage(browserLang.match(/en_us|de_de/) ? browserLang : this.getDefaultLanguage());
  }

  private loadLanguage(lang: string): void {
    if (!isEmptyString(lang)) {
      this.translate.setDefaultLang(lang);
      this.translate.use(lang).pipe()
        .subscribe({
          next: (resp) => {
            this.localizations = resp;
          },
          error: (err) => {
            console.error(`Problem with '${lang}' language initialization.'`, err);
          },
          complete: () => {

          },
        });
    }
  }
}
