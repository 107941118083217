import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DirectionService {
  private data = new BehaviorSubject('');
  public currentData = this.data.asObservable();

  constructor() {
  }

  public updateDirection(item: any): void {
    this.data.next(item);
  }
}
