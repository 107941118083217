import {Component, Inject, OnInit} from '@angular/core';
import {DirectionService} from '../../../core/service/direction.service';
import {Config, CONFIG_TOKEN, UnsubscribeOnDestroyDirectiveAdapter} from '@hrm-pwa/hrm-pwa-api';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [],
})
export class MainLayoutComponent extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit {
  public direction: string;

  constructor(
    private directoryService: DirectionService,
    @Inject(CONFIG_TOKEN) protected config_token: Config,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subs.sink = this.directoryService.currentData.subscribe((currentData) => {
      if (currentData) {
        this.direction = currentData;
      } else {
        if (localStorage.getItem('isRtl')) {
          if (localStorage.getItem('isRtl') === 'true') {
            this.direction = 'rtl';
          } else if (localStorage.getItem('isRtl') === 'false') {
            this.direction = 'ltr';
          }
        } else {
          localStorage.setItem('rtl', 'false');
          if (this.config_token.layout.rtl == true) {
            this.direction = 'rtl';
          } else {
            this.direction = 'ltr';
          }
        }
      }
    });
  }
}
