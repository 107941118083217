import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {Config, CONFIG_TOKEN, UnsubscribeOnDestroyDirectiveAdapter} from '@hrm-pwa/hrm-pwa-api';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'pwa-update-toast',
  templateUrl: './pwa-update-toast.component.html',
  styleUrls: ['./pwa-update-toast.component.scss'],
})
export class PwaUpdateToastComponent extends UnsubscribeOnDestroyDirectiveAdapter {

  public readonly message;

  constructor(
    public snackBarRef: MatSnackBarRef<PwaUpdateToastComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    @Inject(CONFIG_TOKEN) private config_token: Config,
  ) {
    super();
    this.message = `New ${this.config_token.name} version is available. Load New Version?`;
  }

  public async updatePWA(): Promise<void> {
    this.snackBarRef.dismiss();
    if (this.data.swUpdate instanceof SwUpdate && this.data.swUpdate.isEnabled) {
      const reload = await this.data.swUpdate.activateUpdate();
      if (reload) {
        window.location.reload();
      }
    }
  }
}
