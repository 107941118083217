import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BehaviorSubject, map, Observable, of, switchMap, tap, throwError} from 'rxjs';
import {
  BearerToken,
  Bean,
  User,
  JSONRestResponseLogin,
  JSONRestResponseEntryList, KeyValue, AttachedFile, ACLSummary,
} from '../models/models';
import {HTTPBaseService} from "./http-base.service";
import {API_URL, RFC_URL} from "../constants";
import {catchError} from "rxjs/operators";
import {Config, CONFIG_TOKEN} from "../config";
import {isEmpty, isEmptyString, isTrueProperty} from "../utils/utils";
import {Role, UserStatus} from "../models/enums";
import {DateUtils} from "../utils/date-utils";

@Injectable()
export class ApiService<T extends Bean> extends HTTPBaseService {
  public data: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected _module: string;
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private columns: string[] = [];

  public get records(): T[] {
    return this.data.value;
  }

  public set module(module: string) {
    if (isEmptyString(this._module)) {
      this._module = module;
    }
  }

  public get module(): string {
    return this._module;
  }

  constructor(@Inject(CONFIG_TOKEN) protected config_token: Config,
              protected http: HttpClient,
              @Inject(API_URL) apiUrl: string,
              @Inject(RFC_URL) rfcUrl: string) {
    super(http, apiUrl, rfcUrl);
    this.pageSize = config_token.pageSize ?? this.pageSize;
  }

  public flushRecords(records: T[]): void {
    this.data.next(records);
    this.isLoading.next(false);
  }

  public getRecord(id: string): T | undefined {
    return this.records.find((x) => x.id === id);
  }

  /** START CRUD METHODS */
  public fetch(columns?: string[], filters?: KeyValue[], url?: string, params?: HttpParams): Observable<T[]> {
    if (isEmptyString(this.module)) {
      return of([]);
    }
    if (columns?.length) {
      this.columns = columns;
    }
    url = `${url ?? this.baseApiUrl}/module/${this.module}?page[number]=1&page[size]=${this.pageSize}`;
    if (filters?.length) {
      url += `&filter`;
      filters.forEach((filter) => {
        url += `[${filter.key}]`;
        url += filter.meta ? `[${filter.meta}]` : `[eq]`;
        url += `=${filter.value}`;
      });
    }
    this.isLoading.next(true);
    return this.doBeanHTTPRequest<T>(url, 'GET', params).pipe(
      tap((records: T[]) => {
        this.buildUnionSearchString(records);
        this.flushRecords(records);
      }),
      catchError((err: Error) => this.handleError(err)),
    );
  }

  public addRecord(record: T): Observable<T> {
    if (!isEmptyString(record.id)) {
      record.new_with_id = true;
    }
    const url = `${this.baseApiUrl}/module`;
    const payload = {
      data: {
        type: this.module,
        attributes: {
          ...record,
        }
      }
    };
    return this.doBeanHTTPRequest<T>(url, 'POST', payload).pipe(
      tap((updatedRecords: T[]) => {
        this.buildUnionSearchString(updatedRecords);
        updatedRecords.forEach((updatedRecord) => {
          // For add we're just pushing a new row inside DataService
          this.records.unshift(updatedRecord);
        })
        this.flushRecords(this.records);
      }),
      map((updatedRecords: T[]) => updatedRecords.shift()),
      catchError((err: Error) => this.handleError(err)),
    );
  }

  public updateRecord(record: Partial<T>): Observable<T> {
    delete record.unionSortString;
    const url = `${this.baseApiUrl}/module`;
    const payload = {
      data: {
        type: this.module,
        id: record.id,
        attributes: {
          ...record,
        }
      }
    };
    return this.doBeanHTTPRequest<T>(url, 'PATCH', payload).pipe(
      tap((updatedRecords: T[]) => {
        this.buildUnionSearchString(updatedRecords);
        updatedRecords.forEach((updatedRecord) => {
          // When using an edit things are little different, firstly we find record inside DataService by id
          const foundIndex = this.records.findIndex(
            (x) => x.id === updatedRecord.id
          );
          // Then you update that record using data from dialogData (values you entered)
          this.records[foundIndex] = {
            ...this.records[foundIndex],
            ...updatedRecord,
          };
        })
        this.flushRecords(this.records);
      }),
      map((updatedRecords: T[]) => updatedRecords.shift()),
      catchError((err: Error) => this.handleError(err)),
    );
  }

  public deleteRecord(id: string | number): void {
    const foundIndex = this.records.findIndex(
      (x) => x.id === id
    );
    // for delete we use splice in order to remove single object from DataService
    this.records.splice(foundIndex, 1);

    /*  this.httpClient.delete(this.API_URL + id).subscribe(data => {
      console.log(id);
      },
      (err: HttpErrorResponse) => {
         // error code here
      }
    );*/
  }

  /** END CRUD METHODS */

  /**
   * send all attached files to back-end and use custom service. any "upload" endpoint is filtered by .htaccess to 403
   */
  public attachFiles(files: AttachedFile[], record: T): Observable<any> {
    if (!Array.isArray(files) || !files.length || isEmptyString(record.id)) {
      return of(false);
    }
    const formData: FormData = new FormData();
    files.forEach((f: AttachedFile) => {
      formData.append(f.name, f.file, f.filename);
      formData.append('id', record.id);
      formData.append('module', this.module);
    });
    const url = `${this.baseApiUrl}/custom/module/attachFiles`;
    return this.doHTTPRequest(url, 'POST', formData).pipe(
      tap((attachedFiles: AttachedFile[]) => {
        // just update the internal reference now
        record = this.getRecord(record.id);
        attachedFiles.forEach((attachedFile) => {
          record[attachedFile.name] = attachedFile.filename;
        })
        this.flushRecords(this.records);
      }),
    );
  }

  public loginClient(username: string, password: string): Observable<BearerToken | undefined> {
    /**
     * preparing OAuth2 SuiteCRM
     * https://docs.suitecrm.com/developer/api/developer-setup-guide/json-api/
     * Verify if rewrite module is installed and activated => means /etc/apache2/apache2.conf
     *
     * Repair -> .htaccess file
     * Define OAuth2 clients inside SuiteCRM application (admin section)
     */
    const params = {
      grant_type: 'password',
      client_id: this.config_token.authConfig.client_id,
      client_secret: this.config_token.authConfig.client_secret,
      redirect_uri: this.config_token.authConfig.redirect_uri,
      username,
      password,
    };
    const url = this.baseApiUrl.replace(`V${this.config_token.apiVersion}`, 'access_token');
    return this.http.post<BearerToken>(url, params).pipe(
      catchError((err: Error) => this.handleError(err)),
    );
  }

  public authenticateUser(user_name: string, password: string): Observable<User | null> {
    const payload = {
      user_auth: {
        user_name,
        password: password,
      },
      application_name: this.config_token.name,
      name_value_list: [],
    };

    const body = new URLSearchParams();
    body.set('method', 'login');
    body.set('input_type', 'JSON');
    body.set('response_type', 'JSON');
    body.set('rest_data', JSON.stringify(payload));

    let loginResponse;
    return this.doFormEncodedRequest<JSONRestResponseLogin>(body).pipe(
      switchMap((response) => {
        if (isEmpty(response) || isEmptyString(response.id)) {
          return of(null);
        }
        loginResponse = response;
        const payload = {
          session: response.id,
          module_name: response.module_name,
          id: response.name_value_list.user_id?.value as string,
          select_fields: [],
          link_name_to_fields_array: [{
            name: 'email_addresses',
            value: ['email_address', 'opt_out', 'primary_address']
          }],
        };
        const body = new URLSearchParams();
        body.set('method', 'get_entry');
        body.set('input_type', 'JSON');
        body.set('response_type', 'JSON');
        body.set('rest_data', JSON.stringify(payload));

        return this.doFormEncodedRequest<JSONRestResponseEntryList>(body);
      }),
      map((response) => {
        if (isEmpty(response) || !Array.isArray(response.entry_list)) {
          return null;
        }
        const user = response.entry_list.shift();
        const role = user.name_value_list.is_admin.value === '1' ? Role.Admin : Role.Employee;
        const img = role === Role.Employee ? 'assets/images/user/employee.png' : 'assets/images/user/admin.png';
        const user_default_dateformat = DateUtils.userFormatToNorm(loginResponse.name_value_list.user_default_dateformat?.value);
        const user_default_timeformat = DateUtils.userFormatToNorm(loginResponse.name_value_list.user_default_timeformat?.value);
        return {
          id: user.id,
          username: user_name,
          img,
          password,
          role,
          user_language: loginResponse.name_value_list.user_language?.value,
          user_default_dateformat,
          user_default_timeformat,
          user_decimal_separator: loginResponse.name_value_list.user_decimal_separator?.value,
          user_number_separator: loginResponse.name_value_list.user_number_separator?.value,
          name: user.name_value_list.name.value,
          first_name: user.name_value_list.first_name.value,
          last_name: user.name_value_list.last_name.value,
          title: user.name_value_list.title.value,
          department: user.name_value_list.department.value,
          status: user.name_value_list.status.value as UserStatus,
          address_street: user.name_value_list.address_street.value,
          address_postalcode: user.name_value_list.address_postalcode.value,
          address_city: user.name_value_list.address_city.value,
          address_state: user.name_value_list.address_state.value,
          address_country: user.name_value_list.address_country.value,
          email1: user.name_value_list.email1.value,
          phone_work: user.name_value_list.phone_work.value,
          phone_mobile: user.name_value_list.phone_mobile.value,
        } as User;
      }),
    );
  }

  public loadACLByUser(user: User): Observable<ACLSummary | null> {
    if (user.role === Role.Admin || user.role === Role.All) {
      return of(null);
    }
    const url = `${this.baseApiUrl}/custom/loadACLByUser?user_id=${user.id}`;
    return this.doHTTPRequest<ACLSummary>(url, 'GET').pipe(
      catchError((err: Error) => this.handleError(err)),
    );
  }

  protected handleError(err: HttpErrorResponse | Error): Observable<never> {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401 || err.status === 403) {
        console.error('Error loading records', err);
      }
    } else {
      console.error('Error loading records', err);
    }
    this.flushRecords([]);
    return throwError(() => err);
  }

  protected mapToBeanObject<T>(record: { [key: string]: string }): T {
    return {
      ...record,
      date_entered: DateUtils.parseDateTime(record.date_entered, DateUtils.API_DATE_TIME_FORMAT),
      date_modified: DateUtils.parseDateTime(record.date_modified, DateUtils.API_DATE_TIME_FORMAT),
      deleted: isTrueProperty(record.deleted),
    } as unknown as T;
  }

  protected columnToUserString(value: unknown, column?: string): string {
    if (!isEmpty(value)) {
      if (value instanceof Date) {
        let format = this.currentUser.user_default_dateformat;
        if (isEmptyString(format)) {
          format = DateUtils.DEFAULT_DATE_FORMAT;
        }
        return DateUtils.formatDate(value, format);
      } else {
        return value.toString();
      }
    }
    return '';
  }

  private buildUnionSearchString(records: T[]): void {
    if (this.columns.length) {
      records.forEach((record) => {
        record.unionSortString = "";
        this.columns.forEach((c) => {
          const value = record[c as keyof T];
          record.unionSortString += this.columnToUserString(value, c);
        });
        record.unionSortString = record.unionSortString.toLowerCase();
      });
    }
  }
}
