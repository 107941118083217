import {Component, Inject, OnInit} from "@angular/core";
import {
  Config,
  CONFIG_TOKEN,
  UnsubscribeOnDestroyDirectiveAdapter,
  AuthService,
  formatString, LanguageService
} from "@hrm-pwa/hrm-pwa-api";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "no-employee-dialog",
  templateUrl: "./no-employee-dialog.component.html",
  styleUrls: ["./no-employee-dialog.component.scss"],
})
export class NoEmployeeDialogComponent extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit {
  public noEmployeeMsg = '';

  constructor(
    @Inject(CONFIG_TOKEN) protected config_token: Config,
    public dialogRef: MatDialogRef<NoEmployeeDialogComponent>,
    private authService: AuthService,
    protected languageService: LanguageService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.noEmployeeMsg = formatString(this.languageService.getText('USERS.NO_EMPLOYEE_MESSAGE'), this.currentUser.name, this.config_token.name);
  }

  public close(): void {
    this.dialogRef.close(0);
  }

  public logout(): void {
    this.close();
    this.authService.logout();
  }
}
