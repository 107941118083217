import {Component, OnInit} from '@angular/core';
import {timer} from 'rxjs';
import {map, share} from "rxjs/operators";
import {DateUtils, UnsubscribeOnDestroyDirectiveAdapter} from "@hrm-pwa/hrm-pwa-api";

@Component({
  selector: 'simple-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit {

  public rxTime: string;

  constructor() {
    super();
  }

  public ngOnInit() {
    // Using RxJS Timer
    this.subs.sink = timer(0, 1000).pipe(
      map(() => new Date()),
      share()
    ).subscribe(time => {
      this.rxTime = DateUtils.formatDateTime(time);
    });
  }
}
