import {MenuInfo} from "@hrm-pwa/hrm-pwa-api";

export const sidebarItems: MenuInfo[] = [
  /*  {
      path: "",
      title: "MENUITEMS.MAIN.TEXT",
      moduleName: "",
      iconType: "",
      icon: "",
      class: "",
      groupTitle: true,
      badge: "",
      badgeClass: "",
      role: ["All"],
      submenu: [],
    },*/
  {
    path: "/employee/dashboard",
    title: "DASHBOARD.MY",
    moduleName: "dashboard",
    iconType: "feather",
    icon: "airplay",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },
  {
    path: "/employee/HRM_Leaves",
    title: "HRM_LEAVES.MY",
    moduleName: "HRM_Leaves",
    iconType: "feather",
    icon: "file-text",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },
  {
    path: "/employee/HRM_Employees",
    title: "HRM_EMPLOYEES.MY",
    moduleName: "HRM_Employees",
    iconType: "feather",
    icon: "users",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Employee"],
    submenu: [],
  },
  {
    path: "/employee/Tasks",
    title: "TASKS.MY",
    moduleName: "Tasks",
    iconType: "feather",
    icon: "command",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },
  {
    path: "/employee/settings",
    title: "DASHBOARD.SETTINGS.MY",
    moduleName: "settings",
    iconType: "feather",
    icon: "settings",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },
];
