import {Bean, GKeyValue, KeyValue, User} from "../models/models";
import {ACLAction, ACLInterAction, Role} from "../models/enums";

/**
 * Allows short hand property binding to activate an option.
 *
 * ### Example
 *
 * ```typescript
 * class InputComponent {
 *   private _required = false;
 *   @Input()
 *   public set requiredValue(v: boolean) {
 *     this._required = isTrueProperty(v);
 *   }
 * }
 * ```
 *
 * ```html
 * <huk-input requiredValue></huk-input>
 * ```
 *
 * Otherwise it has to be written as complete property binding:
 * ```html
 * <huk-input [requiredValue]="true"></huk-input>
 * ```
 */
export function isTrueProperty(value: unknown): boolean {
  if (typeof value === 'string') {
    switch (value.toLowerCase().trim()) {
      // short hand attribute '' => <component required></component>. ne need for exact value, just by defining the property and its default string value is enough to be set to "true".
      case 'true':
      case 'on':
      case '1':
        return true;
      case 'false':
      case 'off':
      case '0':
        return false;
      default:
        return !!value;
    }
  }
  return !!value;
}

/**
 * check, if value is NULL or UNDEFINED
 * replacement for deprecated utils function suggested by nodejs
 * https://nodejs.org/api/util.html#util_util_isnullorundefined_object
 */
export function isEmpty(value?: unknown): boolean {
  return value === undefined || value === null;
}

/**
 * check, if value is NULL or UNDEFINED or "" (by removing whitespaces)
 */
export const isEmptyString = (value: string | unknown): boolean => {
  if (isEmpty(value)) {
    // is empty => can not be a string either, thus it is falsy
    return true;
  }
  return (value as string).toString().trim().length === 0;
};

/**
 * check, if value represents a valid Date object only(!)
 */
export const isValidDate = (value: unknown): boolean => {
  if (isEmptyString(value)) {
    // is empty or empty string => can not be any date object, thus it is falsy
    return false;
  }
  if (value instanceof Date) {
    // if value as Date is invalid, it will return 'NaN' on getTime()
    return !Number.isNaN(value.getTime());
  }
  return false;
};


/**
 * A bitwise or operator can be used to truncate floating point values, works for positives as well as negatives
 */
export const float2int = (value: number): number => {
  if (isNaN(value)) {
    return 0;
  }
  // eslint-disable-next-line no-bitwise
  return value | 0;
};

export function isBetween(value: number, min: number, max: number): boolean {
  return min <= value && value <= max;
}

export function enumToArray<T>(enumeration: T): string[] {
  let entries: string[] = [];
  const keys = Object.keys(enumeration);
  keys.forEach((key) => {
    const value = enumeration[key as keyof T] as unknown;
    entries.push(isEmpty(value) ? '' : value as string);
  });
  return entries;
}

export function enumToGKeyValueArray<T>(enumeration: T): GKeyValue[] {
  let entries: GKeyValue[] = [];
  const keys = Object.keys(enumeration);
  keys.forEach((key) => {
    const value = enumeration[key as keyof T] as unknown;
    entries.push({[key as string]: isEmpty(value) ? '' : value as string});
  });
  return entries;
}

export function enumToKeyValueArray<T>(enumeration: T): KeyValue[] {
  let entries: KeyValue[] = [];
  const keys = Object.keys(enumeration);
  keys.forEach((key) => {
    const value = enumeration[key as keyof T] as unknown;
    entries.push({
      key,
      value: isEmpty(value) ? '' : value as string,
    });
  });
  return entries;
}

export const valueByKey = <Z extends object, U extends keyof Z>(obj: Z) => (k: U): Z[U] => obj[k];

export const getCurrentUser = (): User => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  return currentUser;
};

export const hexToRGB = (h: string, a: number) => {
  let r, g, b;
  h = '#' + h.replace(/\#/gi, '');
  a = !!a ? a : 1;
  if (h.length == 4) {
    // 3 digits
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];
  } else if (h.length == 7) {
    // 6 digits
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }
  if (localStorage.getItem("theme")) {
    if (localStorage.getItem("theme") === "dark") {
      a += 0.5;
    }
    if (localStorage.getItem("theme") === "light") {
      a = 0.5;
    }
  } else {
    a = 0.5;
  }
  return "rgba(" + +r + "," + +g + "," + +b + "," + a + ")";
};

export const base64ToPdfBlob = (base64String: string, mimeType = 'application/octet-stream'): Blob => {
  const binary = atob(base64String.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return new Blob([view], {type: mimeType});
}

export const canDo = <T extends Bean>(action: ACLInterAction, category: string, record?: T): boolean => {
  const user = getCurrentUser();
  if (user.role !== Role.Admin && user.role !== Role.All) {
    if (isEmpty(user.acl)) {
      // no acl information provided => restrict everything
      return false;
    }
    if (!user.acl.permissions.hasOwnProperty(category)) {
      // no acl information provided for wanted module
      // => maybe forgotten or name of standalone like "dashboard" Angular module given
      // => allow access
      return true;
    }
    const module = user.acl.permissions[category].module;
    if (module.access.aclaccess === ACLAction.ACL_ALLOW_DISABLED) {
      // module is disabled => restrict everything
      return false;
    }
    if (action === ACLInterAction.ACCESS && module.access.aclaccess === ACLAction.ACL_ALLOW_ENABLED) {
      // module is enable => allow access
      return true;
    }
    let aclAction = module[action]?.aclaccess ?? ACLAction.ACL_ALLOW_NONE as ACLAction;
    if (aclAction === ACLAction.ACL_ALLOW_DISABLED || aclAction === ACLAction.ACL_ALLOW_NONE) {
      // no permission given for wanted module and action => restrict interaction
      return false;
    }
    if (aclAction === ACLAction.ACL_ALLOW_ALL || aclAction === ACLAction.ACL_ALLOW_DEFAULT) {
      // all permissions given or not defined => use default behavior and allow all
      return true;
    }
    if (aclAction === ACLAction.ACL_ALLOW_OWNER) {
      if (record?.id && !record?.new_with_id) {
        if (record.assigned_user_id === user.id) {
          // user owns the record => allow interaction
          return true;
        }
        if (record.created_by === user.id && isEmptyString(record.assigned_user_id)) {
          // user owns the record => allow interaction
          return true;
        }
      } else {
        // user creates record => allow interaction
        return true;
      }
    }
  } else {
    // admin
    return true;
  }
  // fallback not found or defined => restrict interaction
  return false;
}

