import {Component, Inject, OnInit} from "@angular/core";
import {Event, Router, NavigationStart, NavigationEnd} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Config, CONFIG_TOKEN, UnsubscribeOnDestroyDirectiveAdapter} from "@hrm-pwa/hrm-pwa-api";
import {SwUpdate, VersionEvent, VersionReadyEvent} from "@angular/service-worker";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PwaUpdateToastComponent} from "./shared/components/pwa-update-toast/pwa-update-toast.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit {
  public currentUrl: string;

  constructor(
    public _router: Router,
    private swUpdate: SwUpdate,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    @Inject(CONFIG_TOKEN) protected config_token: Config,
  ) {
    super();
    document.title = this.config_token.name;
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });
  }

  public isVersionReadyEvent(upd: VersionEvent): upd is VersionReadyEvent{
      return (upd as VersionReadyEvent).latestVersion !== undefined;
  }

  public ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
      this.subs.sink = this.swUpdate.versionUpdates.subscribe({
        next: (upd) => {
          if(this.isVersionReadyEvent(upd)) {
            localStorage.setItem('pwa-version-hash-current', upd.currentVersion.hash);
            localStorage.setItem('pwa-version-hash-latest', upd.latestVersion.hash);
            this.snackBar.openFromComponent(PwaUpdateToastComponent, {
              data: {
                swUpdate: this.swUpdate,
              },
              ...{
                verticalPosition: 'bottom',
                horizontalPosition: 'center',
                panelClass: 'style-success',
              },
            });
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }
}
