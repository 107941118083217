import {AfterViewChecked, ChangeDetectorRef, Directive} from '@angular/core';
import {ConfigService, UnsubscribeOnDestroyDirectiveAdapter} from "@hrm-pwa/hrm-pwa-api";

@Directive({
  selector: '[color-mode]',
  inputs: ['class'],
  host: {
    '[class]': 'class',
  },
})
export class ColorModeDirective extends UnsubscribeOnDestroyDirectiveAdapter implements AfterViewChecked {
  public class = '';
  private variant = '';

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected inConfig: ConfigService,
  ) {
    super();
  }

  public ngAfterViewChecked(): void {
    const variant = localStorage.getItem('theme') ?? this.inConfig.configData.variant;
    if (variant !== this.variant) {
      setTimeout(() => {
        const regex = new RegExp(`col-${this.variant}?`,);
        this.class = regex.test(this.class) ? this.class.replace(`col-${this.variant}`, `col-${variant}`) : `${this.class} col-${variant}`;
        this.variant = variant;
        this.changeDetector.detectChanges();
      });
    }
  }
}
