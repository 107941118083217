import {NgModule} from "@angular/core";
import {BreadcrumbComponent} from "./breadcrumb/breadcrumb.component";
import {BaseListComponent} from "./crud/list/base-list.component";
import {BaseDetailComponent} from "./crud/detail/base-detail.component";
import {BaseEditComponent} from "./crud/edit/base-edit.component";
import {BaseDeleteComponent} from "./crud/delete/base-delete.component";
import {FeatherIconsComponent} from "./feather-icons/feather-icons.component";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerModule} from "ngx-spinner";
import {MaterialModule} from "../material.module";
import {FeatherModule} from "angular-feather";
import {allIcons} from "angular-feather/icons";
import {HRMPWAApiModule} from "@hrm-pwa/hrm-pwa-api";
import {ClockComponent} from "./clock/clock.component";
import {FileUploadComponent} from "./file-upload/file-upload.component";
import {NoEmployeeDialogComponent} from "./no-employee-dialog/no-employee-dialog.component";
import {AclEditButtonComponent} from "./interactions/acl-edit-button/acl-edit-button.component";
import {AclAddButtonComponent} from "./interactions/acl-add-button/acl-add-button.component";
import {AclListButtonComponent} from "./interactions/acl-list-button/acl-list-button.component";
import {AclDeleteButtonComponent} from "./interactions/acl-delete-button/acl-delete-button.component";
import {AclRefreshButtonComponent} from "./interactions/acl-refresh-button/acl-refresh-button.component";
import {CancelButtonComponent} from "./interactions/cancel-button/cancel-button.component";
import {AclSaveButtonComponent} from "./interactions/acl-save-button/acl-save-button.component";
import {DirectivesModule} from "../directives/directives.module";
import {PwaUpdateToastComponent} from "./pwa-update-toast/pwa-update-toast.component";

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgbModule,
  NgxSpinnerModule,
  MaterialModule,
  FeatherModule,
  HRMPWAApiModule,
  DirectivesModule,
];

const DECLARATIONS = [
  PwaUpdateToastComponent,
  FeatherIconsComponent,
  BreadcrumbComponent,
  BaseListComponent,
  BaseDetailComponent,
  BaseEditComponent,
  BaseDeleteComponent,
  ClockComponent,
  FileUploadComponent,
  NoEmployeeDialogComponent,
  AclAddButtonComponent,
  AclEditButtonComponent,
  AclListButtonComponent,
  AclRefreshButtonComponent,
  AclDeleteButtonComponent,
  AclSaveButtonComponent,
  CancelButtonComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES, FeatherModule.pick(allIcons)],
  exports: [...DECLARATIONS, ...MODULES],
})
export class ComponentsModule {
}
