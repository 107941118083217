import moment from 'moment-timezone';
import {getCurrentUser, isEmptyString, isValidDate} from './utils';
import {Inject, Injectable, LOCALE_ID, PLATFORM_ID} from '@angular/core';
import {MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';
import {Platform} from "@angular/cdk/platform";

@Injectable({
  providedIn: 'root'
})
export class PickDateAdapter extends NativeDateAdapter {
  constructor(
    @Inject(LOCALE_ID) browserLocale: string,
    @Inject(PLATFORM_ID) platformId: Platform,
    @Inject(MAT_DATE_LOCALE) matLocale: string,
  ) {
    super(!isEmptyString(matLocale) ? matLocale : browserLocale, platformId);
    this.setLocale(!isEmptyString(matLocale) ? matLocale : browserLocale);
  }

  public format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return DateUtils.formatDate(date, DateUtils.DEFAULT_DATE_FORMAT);
    } else {
      return super.format(date, displayFormat);
    }
  }

  public parse(value: string): Date | null {
    const user = getCurrentUser();
    let format = user.user_default_dateformat;
    if (isEmptyString(format)) {
      format = DateUtils.DEFAULT_DATE_FORMAT;
    }
    return DateUtils.parseDate(value, format);
  }

  public getFirstDayOfWeek(): number {
    // 0 => Sunday, 1 => Monday
    return 1;
  }
}

/**
 * utility class for static date algorithm operations
 *
 * https://angular.io/guide/angular-compiler-options
 * @dynamic => You can choose to suppress the error emitted by this option for an exported symbol by including @dynamic in the comment documenting the symbol.
 */
export class DateUtils {
  public static readonly DEFAULT_DATE_FORMAT: string = 'DD.MM.YYYY';
  public static readonly DEFAULT_TIME_FORMAT: string = 'HH:mm:ss';
  public static readonly DEFAULT_DATE_TIME_FORMAT = `${DateUtils.DEFAULT_DATE_FORMAT} ${DateUtils.DEFAULT_TIME_FORMAT}`;

  public static readonly API_DATE_FORMAT: string = 'YYYY-MM-DD';
  public static readonly API_TIME_FORMAT: string = 'HH:mm:ss';
  public static readonly API_DATE_TIME_FORMAT = `${DateUtils.API_DATE_FORMAT} ${DateUtils.API_TIME_FORMAT}`;

  /**
   * parses input date string value by given format or default into Date object
   * every input date will be either adjusted into local timezone, or is adjusted into UTC time
   * be aware! vanilla Date JS always uses local time zone and converts time again => do not operate with returned date
   */
  public static parseDate(
    value: string,
    pattern: string = DateUtils.DEFAULT_DATE_FORMAT,
    isUTC: boolean = false,
  ): Date | null {
    if (isEmptyString(value)) {
      return null;
    }
    const dateMoment = moment(value, pattern);
    if (dateMoment.isValid()) {
      const offset = dateMoment.utcOffset();
      return DateUtils.modifyDate(dateMoment.toDate(), !isUTC ? offset : offset * -1, 'minute');
    }
    return null;
  }

  /**
   * parses input datetime string value by given format or default into Date (-Time) object
   */
  public static parseDateTime(
    value: string,
    pattern: string = DateUtils.DEFAULT_DATE_TIME_FORMAT,
    isUTC: boolean = false,
  ): Date | null {
    return DateUtils.parseDate(value, pattern, isUTC);
  }

  /**
   * parses input date | string value into wanted and formatted string date representation
   */
  public static parseDateAsString(
    value: string | Date,
    toPattern: string = DateUtils.DEFAULT_DATE_FORMAT,
    fromPattern: string = DateUtils.API_DATE_FORMAT,
    isUTC: boolean = false,
  ): string {
    if (isEmptyString(value)) {
      return '';
    }
    let dateMoment;
    if (value instanceof Date) {
      dateMoment = !isUTC ? moment(value) : moment(value).utc();
    } else {
      dateMoment = !isUTC ? moment.utc(value, fromPattern) : moment(value, fromPattern).utc();
    }
    if (dateMoment.isValid()) {
      return dateMoment.format(toPattern);
    }
    return '';
  }

  public static get maxDate(): Date | null {
    return DateUtils.parseDate('31.12.2999');
  }

  public static get minDate(): Date | null {
    return DateUtils.parseDate('01.01.1970');
  }

  /**
   * date string is ISO 8601 format?
   * 2016-10-13T08:35:47.510Z => true
   * 2011-10-10T14:48:00 => true
   */
  public static isISO8601(value: string): boolean {
    return moment(value, moment.ISO_8601).isValid();
  }

  /**
   * format given Date object into wanted date pattern (default DD.MM.YYYY)
   */
  public static formatDate(
    date?: Date | null,
    pattern: string = DateUtils.DEFAULT_DATE_FORMAT,
  ): string {
    if (date) {
      return moment(date).format(pattern);
    }
    return '';
  }

  /**
   * format given Date object into wanted datetime pattern (default DD.MM.YYYY HH:mm:ss)
   */
  public static formatDateTime(
    date?: Date | null,
    pattern: string = DateUtils.DEFAULT_DATE_TIME_FORMAT,
  ): string {
    if (date) {
      return moment(date).format(pattern);
    }
    return '';
  }

  public static nextMonth(date?: Date | null): number {
    if (!date) {
      date = new Date();
    }
    return moment(date).add(1, 'month').get('month');
  }

  public static getYear(
    date?: unknown,
    pattern: string = DateUtils.DEFAULT_DATE_FORMAT,
  ): number | undefined {
    if (isEmptyString(date)) {
      date = DateUtils.getCurrentDate();
    }
    if (typeof date === 'string') {
      date = DateUtils.parseDate(date, pattern);
    }
    if (isValidDate(date)) {
      return (date as Date).getFullYear();
    }
    return undefined;
  }

  public static compareDays(date1: Date, date2: Date): number {
    if (moment(date1).isSame(date2, 'day')) {
      return 0;
    }
    if (moment(date1).isBefore(date2, 'day')) {
      return -1;
    }
    return 1;
  }

  /**
   * compare two Date objects
   * @Date date1
   * @Date date2
   * @moment.unitOfTime.StartOf granularity - use 'day' for matching day value, e.g. 1.1.2000 4am === 1.1.2000 5pm
   * returns {number} - 0 === equal; -1 === (date1 < date2); 1 === (date1 > date2)
   */
  public static compareDates(
    date1: Date,
    date2: Date,
    granularity?: moment.unitOfTime.StartOf,
  ): number {
    if (moment(date1).isSame(date2, granularity)) {
      return 0;
    }
    if (moment(date1).isBefore(date2, granularity)) {
      return -1;
    }
    return 1;
  }

  /**
   * is given Date object between max and min values?
   * @Date date
   * @Date dateMin min allowed Date value
   * @Date dateMax max allowed Date value
   * return {boolean}
   * */
  public static isBetween(date: Date, dateMin: Date, dateMax: Date): boolean {
    return moment(date).isBetween(moment(dateMin), moment(dateMax), null, '[]');
  }

  public static modifyDate(
    date: Date | null,
    value: number,
    base: moment.unitOfTime.Base,
    direction: 1 | -1 = 1,
  ): Date | null {
    if (!isValidDate(date)) {
      date = DateUtils.getCurrentDate();
    }
    if (!value) {
      return date;
    }
    value = value * direction;
    if (value < 0) {
      return moment(date).subtract(Math.abs(value), base).toDate();
    }
    return moment(date).add(Math.abs(value), base).toDate();
  }

  /**
   * replaces non normed date format values into right schema for moment.js
   */
  public static userFormatToNorm(format: string): string {
    format = format.replace(/d+/i, 'DD');
    format = format.replace(/m+/i, 'MM');
    format = format.replace(/y+/i, 'YYYY');
    format = format.replace(/h+/i, 'HH');
    format = format.replace(/i+/i, 'mm');
    format = format.replace(/s+/i, 'ss');
    return format;
  }

  public static getCurrentDate(): Date {
    return moment().startOf('day').toDate();
  }

  /**
   * returns any Date by given params. month start at 0 index!
   * default, 1st of current year
   */
  public static setAnyDate(
    day: number = 1,
    month: number = 0,
    year: number = 0,
    hour: number = 0,
    minute: number = 0,
    second: number = 0,
    millisecond: number = 0,
  ): Date {
    if (!year) {
      year = DateUtils.getYear()!;
    }
    /**
     * if you chain multiple actions to construct a date, you should start from a year, then a month, then a day etc.
     * Otherwise, you may get unexpected results, like when day=31 and current month has only 30 days (the same applies to native JavaScript Date manipulation),
     * the returned date will be the 30th of the current month (see month for more details).
     *
     * utc ignores local timezones => https://momentjs.com/docs/#/parsing/utc/
     */
    return moment()
      .utc()
      .year(year)
      .month(month)
      .date(day)
      .hour(hour)
      .minute(minute)
      .second(second)
      .millisecond(millisecond)
      .toDate();
  }

  public static adjustTimeToUTC(date: Date | string): Date {
    let clearDate;
    if (typeof date === 'string') {
      clearDate = DateUtils.parseDate(date);
    }
    // clone the object or create default now
    clearDate = !isValidDate(date) ? moment() : moment(date);
    const offset = clearDate.utcOffset();
    return DateUtils.modifyDate(clearDate.toDate(), offset * -1, 'minute')!;
  }
}
