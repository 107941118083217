import {Pipe, PipeTransform} from '@angular/core';
import {DateUtils} from "../utils/date-utils";
import {getCurrentUser, isEmptyString} from "../utils/utils";

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  public transform(value: Date, format?: string, timezone?: string, locale?: string): string {
    if (isEmptyString(format)) {
      const user = getCurrentUser();
      format = user.user_default_dateformat;
      if (isEmptyString(format)) {
        format = DateUtils.DEFAULT_DATE_FORMAT;
      }
    }
    return DateUtils.formatDate(value, format);
  }
}
