import {Injectable} from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import {AuthService} from "../services/auth.service";
import { isEmpty } from "../utils/utils";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (isEmpty(this.authService.currentUserValue)) {
      this.router.navigate(["/authentication/signin"]);
      return false;
    }
    return true;
  }
}
