export const enumBlankReplacer = '__';

export enum ACLInterAction {
  ACCESS = 'access',
  DELETE = 'delete',
  EDIT = 'edit',
  EXPORT = 'export',
  IMPORT = 'import',
  LIST = 'list',
  MASSUPDATE = 'massupdate',
  VIEW = 'view',
}

export enum ACLAction {
  ACL_ALLOW_ADMIN_DEV = '100',
  ACL_ALLOW_ADMIN = '99',
  ACL_ALLOW_ALL = '90',
  ACL_ALLOW_ENABLED = '89',
  ACL_ALLOW_GROUP = '80', // securitygroup
  ACL_ALLOW_OWNER = '75',
  ACL_ALLOW_NORMAL = '1',
  ACL_ALLOW_DEFAULT = '0',
  ACL_ALLOW_DISABLED = '-98',
  ACL_ALLOW_NONE = '-99',
  ACL_ALLOW_DEV = '95',
}

export enum Role {
  All = "All",
  Admin = "Admin",
  Employee = "Employee",
}

export enum HRM_LeaveStatus {
  Created = "Created",
  Requested = "Requested",
  Approved = "Approved",
  Changed = "Changed",
  Denied = "Denied",
  Undo = "Undone",
}

export enum TaskStatus {
  Not__Started = "Not Started",
  In__Progress = "In Progress",
  Completed = "Completed",
  Pending__Input = "Pending Input",
  Deferred = "Deferred",
}

export enum HRM_EmployeeStatus {
  Active = "Active",
  Terminated = "Terminated",
  Leave__of__Absence = "Leave of Absence",
}

export enum UserStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum HRM_LeaveDayType {
  full_day = "Full Day",
  half_day_am = "Half Day AM",
  half_day_pm = "Half Day PM",
}
