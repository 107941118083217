import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HeaderComponent} from "./header/header.component";
import {PageLoaderComponent} from "./page-loader/page-loader.component";
import {SidebarComponent} from "./sidebar/sidebar.component";
import {RightSidebarComponent} from "./right-sidebar/right-sidebar.component";
import {AuthLayoutComponent} from "./app-layout/auth-layout/auth-layout.component";
import {MainLayoutComponent} from "./app-layout/main-layout/main-layout.component";
import {FeatherModule} from "angular-feather";
import {allIcons} from "angular-feather/icons";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MaterialModule} from "../shared/material.module";
import {HRMPWAApiModule} from "@hrm-pwa/hrm-pwa-api";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
import {SharedModule} from "../shared/shared.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  MaterialModule,
  FeatherModule,
  HRMPWAApiModule,
  PerfectScrollbarModule,
  SharedModule,
];

const DECLARATIONS = [
  HeaderComponent,
  PageLoaderComponent,
  SidebarComponent,
  RightSidebarComponent,
  AuthLayoutComponent,
  MainLayoutComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [
    ...MODULES,
    FeatherModule.pick(allIcons),
  ],
  exports: [...DECLARATIONS, ...MODULES],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class LayoutModule {
}
