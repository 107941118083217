<mat-form-field class="example-full-width file-upload" appearance="outline">
  <mat-label>{{label | translate}}</mat-label>
  <div>
    <mat-toolbar>
      <!-- Display files names -->
      <input matInput [(ngModel)]="fileAttr" readonly name="name"/>
      <!-- Browse Button -->
      <button mat-flat-button color="primary">{{'MAIN.CHOOSE_FILE' | translate}}</button>
    </mat-toolbar>
    <!-- Fetch selected filed on change -->
    <input
      type="file"
      #fileInput
      id="uploadFile"
      (change)="uploadFileEvt($event)"
      name="uploadFile"
      multiple="multiple"
      accept="image/*"
    />
  </div>
</mat-form-field>
