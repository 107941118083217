import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable, of, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {API_URL, RFC_URL} from "../constants";
import {JSONRestResponse, JSONRestResponseData} from '../models/models';
import { UnsubscribeOnDestroyServiceAdapter } from '../utils/adapters/UnsubscribeOnDestroyServiceAdapter';
import {isEmpty} from '../utils/utils';

type SupportedHTTPMethod = 'OPTION' | 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

@Injectable()
export abstract class HTTPBaseService extends UnsubscribeOnDestroyServiceAdapter {
  protected readonly baseApiUrl: string;
  protected readonly baseRfcUrl: string;
  protected pageSize = 10;

  constructor(protected http: HttpClient,
              @Inject(API_URL) apiUrl: string,
              @Inject(RFC_URL) rfcUrl: string) {
    super();
    this.baseApiUrl = `${apiUrl}`;
    this.baseRfcUrl = `${rfcUrl}`;
  }

  /**
   * any open HTTP request docked onto the CRM API without having a bean context
   * @protected
   */
  protected doHTTPRequest<T>(url: string, method: SupportedHTTPMethod, data?: unknown, options?: {}): Observable<T> {
    options = {
      ...options,
      responseType: 'json' as const,
      headers: new Headers({
        'Content-Type': 'application/json',
      })
    };

    let payload:
      | Record<string, unknown>
      | Array<Record<string, unknown>>
      | HttpParams
      | FormData
      | string
      | null = null;
    switch (method) {
      case 'PUT':
        if (data) {
          if (Array.isArray(data)) {
            payload = [...data];
          } else if (typeof data === 'string') {
            payload = data;
          } else {
            payload = {
              ...(data as { [key: string]: unknown }),
            };
          }
        }
        return this.http.put<T>(url, payload, options).pipe(
          catchError((err) => throwError(err)),
        );
      case 'PATCH':
        if (data) {
          if (Array.isArray(data)) {
            payload = [...data];
          } else if (typeof data === 'string') {
            payload = data;
          } else {
            payload = {
              ...(data as { [key: string]: unknown }),
            };
          }
        }
        return this.http.patch<T>(url, payload, options).pipe(
          catchError((err) => throwError(err)),
        );
      case 'POST':
        if (data) {
          if (Array.isArray(data)) {
            payload = [...data];
          } else if (typeof data === 'string') {
            payload = data;
          } else if (data instanceof FormData) {
            payload = data;
          } else {
            payload = {
              ...(data as { [key: string]: unknown }),
            };
          }
        }
        return this.http.post<T>(url, payload, options).pipe(
          catchError((err) => throwError(err)),
        );
      case 'GET':
      default:
        payload = {
          ...options,
        };
        if (data && data instanceof HttpParams) {
          payload = {
            ...payload,
            params: data,
          };
        }
        return this.http.get<T>(url, payload).pipe(
          catchError((err) => throwError(err)),
        );
    }
  }

  /**
   * any HTTP request among registered CRM modules carrying bean context and strict operations on bean.
   * always returns an array of beans from 0-n
   * @protected
   */
  protected doBeanHTTPRequest<T>(url: string, method: SupportedHTTPMethod, data?: unknown, options?: {}): Observable<T[]> {
    options = {
      ...options,
      responseType: 'json' as const,
      headers: new Headers({
        'Content-Type': 'application/json',
      })
    };

    let payload:
      | Record<string, unknown>
      | Array<Record<string, unknown>>
      | HttpParams
      | FormData
      | string
      | null = null;
    switch (method) {
      case 'POST':
        if (data) {
          if (Array.isArray(data)) {
            payload = [...data];
          } else if (typeof data === 'string') {
            payload = data;
          } else {
            payload = {
              ...(data as { [key: string]: unknown }),
            };
          }
        }
        return this.http.post<JSONRestResponse>(url, payload, options).pipe(
          map(response => this.mapToBeanObjects<T>(response)),
          catchError((err) => throwError(err)),
        );
      case 'PATCH':
        if (data) {
          if (Array.isArray(data)) {
            payload = [...data];
          } else if (typeof data === 'string') {
            payload = data;
          } else {
            payload = {
              ...(data as { [key: string]: unknown }),
            };
          }
        }
        return this.http.patch<JSONRestResponse>(url, payload, options).pipe(
          map(response => this.mapToBeanObjects<T>(response)),
          catchError((err) => throwError(err)),
        );
      case 'GET':
      default:
        payload = {
          ...options,
        };
        if (data && data instanceof HttpParams) {
          payload = {
            ...payload,
            params: data,
          };
        }
        const payloadFullList = payload;
        return this.http.get<JSONRestResponse>(url, payload).pipe(
          switchMap((response) => {
            // open observable envelope
            // really hacky, but we need to get the whole list. this can be just achieved by repeated request knowing the total amount
            if (!isEmpty(response.meta)) {
              const totalPages = response.meta['total-pages'] ?? 0;
              const totalPageRecords = response.meta['records-on-this-page'] ?? 0;
              if (totalPages > 1 && totalPageRecords === this.pageSize) {
                url = url.replace(`page[size]=${this.pageSize}`, `page[size]=${totalPages * this.pageSize}`);
                return this.http.get<JSONRestResponse>(url, payloadFullList);
              }
            }
            // not a limited GET list request, then just close envelope again into new observable
            return of(response);
          }),
          map(response => this.mapToBeanObjects<T>(response)),
          catchError((err) => throwError(err)),
        );
      case 'DELETE':
        payload = {
          ...options,
          responseType: 'text' as const,
          body: data ?? {},
        };
        return this.http.delete<JSONRestResponse>(url, payload).pipe(
          map(response => this.mapToBeanObjects<T>(response)),
          catchError((err) => throwError(err)),
        );
    }
  }

  /**
   * call oldschool api style service/v4_1/rest.php endpoints
   * https://support.sugarcrm.com/Documentation/Sugar_Developer/Sugar_Developer_Guide_11.0/Integration/Web_Services/Legacy_API/Methods/
   */
  public doFormEncodedRequest<T>(payload: URLSearchParams): Observable<T> {
    const options = {
      responseType: 'json' as const,
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return this.http.post<T>(this.baseRfcUrl, payload.toString(), options).pipe(
      catchError((err) => throwError(err)),
    );
  }

  protected mapToBeanObjects<T>(response: JSONRestResponse): T[] {
    const data = Array.isArray(response.data) ? response.data : [response.data];
    return data.map((r: JSONRestResponseData) => {
      return {
        id: r.id,
        ...this.mapToBeanObject<T>(r.attributes),
      };
    });
  }

  protected abstract mapToBeanObject<T>(record: { [key: string]: string }): T;
}
