import {FactoryProvider, InjectionToken} from "@angular/core";
import {Config, CONFIG_TOKEN} from "./config";

// https://www.concretepage.com/angular-material/angular-material-datepicker-format
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_DATE_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

export const API_URL = new InjectionToken<string>('serviceApiUrl');
export const RFC_URL = new InjectionToken<string>('serviceAuthUrl');

export function apiUrlFactory(config: Config): string {
  return `${config.crmBaseUrl}/${config.restEndpoint}/V${config.apiVersion}`;
}

export function rfcUrlFactory(config: Config): string {
  return `${config.crmBaseUrl}/${config.rfcEndpoint}`;
}

export const INITIALIZE_API_URL: FactoryProvider = {
  provide: API_URL,
  useFactory: apiUrlFactory,
  deps: [CONFIG_TOKEN],
};

export const INITIALIZE_RFC_URL: FactoryProvider = {
  provide: RFC_URL,
  useFactory: rfcUrlFactory,
  deps: [CONFIG_TOKEN],
};

