import {DOCUMENT} from "@angular/common";
import {AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2,} from "@angular/core";
import {
  InConfiguration,
  UnsubscribeOnDestroyDirectiveAdapter,
  User,
  ConfigService,
  CONFIG_TOKEN, Config, AuthService, LanguageService,
} from "@hrm-pwa/hrm-pwa-api";

const document: any = window.document;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.sass"],
})
export class HeaderComponent extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit, AfterViewInit {
  public config: InConfiguration;
  public currentUser: User;
  public homePage: string;
  public isNavbarCollapsed = true;
  public flagvalue: string;
  public langStoreValue: string;
  public defaultFlag: string;
  public listLang = [
    {text: "MAIN.ENGLISH", flag: "assets/images/flags/us.jpg", lang: "en_us"},
    {text: "MAIN.GERMAN", flag: "assets/images/flags/germany.jpg", lang: "de_de"},
  ];
  public name = '';

  constructor(
    @Inject(CONFIG_TOKEN) protected config_token: Config,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private authService: AuthService,
    public languageService: LanguageService,
  ) {
    super();
    this.name = config_token.name;
  }

  public ngOnInit(): void {
    this.config = this.configService.configData;
    this.currentUser = this.authService.currentUserValue;
    this.homePage = "employee/dashboard";

    this.langStoreValue = localStorage.getItem("lang") ?? this.languageService.getDefaultLanguage();
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    if (!val.length) {
      this.defaultFlag = "assets/images/flags/us.jpg";
    } else {
      this.flagvalue = val[0].flag ?? '';
    }
  }

  public ngAfterViewInit(): void {
    // set theme on startup
    if (localStorage.getItem("theme")) {
      this.renderer.removeClass(this.document.body, this.config.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem("theme") ?? '');
    } else {
      this.renderer.addClass(this.document.body, this.config.variant);
    }

    if (localStorage.getItem("menuOption")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("menuOption") ?? '',
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "menu_" + this.config.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem("choose_logoheader")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_logoheader") ?? '',
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "logo-" + this.config.logo_bg_color
      );
    }

    if (localStorage.getItem("sidebar_status")) {
      if (localStorage.getItem("sidebar_status") === "close") {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      }
    } else {
      if (this.config.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      }
    }
  }

  public callFullscreen(): void {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  public setLanguage(lang: string, flag: string): void {
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }

  public mobileMenuSidebarOpen(event: any, className: string): void {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  public callSideMenuCollapse(): void {
    const hasClass = this.document.body.classList.contains("side-closed");
    if (hasClass) {
      this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    } else {
      this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }

  public logout(): void {
    this.authService.logout();
  }
}
