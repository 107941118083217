import {Injectable} from "@angular/core";
import {InConfiguration} from "../config";

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public configData: InConfiguration;

  constructor() {
    this.setConfigData();
  }

  private setConfigData(): void {
    this.configData = {
      rtl: localStorage.getItem('isRtl') === 'true' ? true : false, // options:  true & false
      variant: localStorage.getItem('theme') ?? 'light', // options:  light & dark
      theme_color: localStorage.getItem('choose_skin')?.replace('theme-', '') ?? 'white', // options:  white, black, purple, blue, cyan, green, orange
      logo_bg_color: localStorage.getItem('choose_skin')?.replace('theme-', '') ?? 'white', // options:  white, black, purple, blue, cyan, green, orange
      sidebar: {
        collapsed: false, // options:  true & false
        backgroundColor: localStorage.getItem('theme') ?? 'light', // options:  light & dark
      },
    };
  }
}
