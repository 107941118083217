import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MaterialModule} from "../material.module";
import {HRMPWAApiModule} from "@hrm-pwa/hrm-pwa-api";
import {ColorModeDirective} from "./color-mode.directive";

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  MaterialModule,
  HRMPWAApiModule,
];

const DECLARATIONS = [
  ColorModeDirective,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [MODULES],
  exports: [...DECLARATIONS, ...MODULES],
})
export class DirectivesModule {
}
