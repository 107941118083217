/**
 * Utility für Numberformatierungen
 */

import {float2int, isEmpty } from "./utils";

export class NumberUtils {
  public static readonly NUM_GRP_SEP: string = '.';
  public static readonly DEC_SEP: string = ',';
  public static readonly CURRENCY_SYMBOL: string = '€';
  public static readonly SYMBOLS: string[] = ['€', '$', '£', '%', 'm²'];

  public static formatStringToNumber(value: string): number {
    return NumberUtils.unformatNumber(value);
  }

  /**
   * format any numeric value into string by using given group sep and decimal sep.
   * e.g: 2015.75 -> "2.015,75"
   * @param value the value for formatting
   * @returns the formatted value
   */
  public static formatNumberToString(value: number): string {
    return NumberUtils.numberFormatFactory(value);
  }

  /**
   * format any numeric value into string by using given group sep and decimal sep plus the currency symbol attached.
   * z.B: 2015.75 -> "2.015,75 €"
   * @param value the value for formatting
   * @returns the formatted value
   */
  public static formatNumberToPrice(
    value: number,
    decSep?: string,
    numGrpSep?: string,
    precision?: number,
    symbol?: string,
    rightPosition: boolean = true,
  ): string {
    return NumberUtils.numberFormatFactory(
      value,
      decSep ?? NumberUtils.DEC_SEP,
      numGrpSep ?? NumberUtils.NUM_GRP_SEP,
      precision ?? 0,
      symbol ?? ` ${NumberUtils.CURRENCY_SYMBOL}`,
      rightPosition,
    );
  }

  /**
   * converts a valid input value into a valid number value. if input is number, do nth
   * if input is a valid string format of any number, do Number() parsing
   * if input is invalid, return NaN
   * @param value to be converted into number
   */
  public static createFloat(value: string | number): number {
    if (typeof value === 'number') {
      return value;
    }

    // left aligned optional pre signs "+-", followed by any number, having optional "." and after decimal numbers or Infinity
    if (/^(\-|\+)?([0-9]+(\.[0-9]*)?|Infinity)$/.test(value)) {
      return Number(value);
    }
    return NaN;
  }

  public static createInt(value: string | number): number {
    if (typeof value === 'number') {
      return Math.floor(value);
    }
    return parseInt(value as string, 10);
  }

  public static numberFormatFactory(
    value: number,
    decSep?: string,
    numGrpSep?: string,
    precision?: number,
    symbol?: string,
    rightPosition?: boolean,
  ): string {
    if (isEmpty(value) || isNaN(value) || value.toString().length === 0) {
      return '';
    }
    precision = isEmpty(precision) ? 2 : Math.abs(precision!);
    decSep = isEmpty(decSep) ? NumberUtils.DEC_SEP : decSep;
    numGrpSep = isEmpty(numGrpSep) ? NumberUtils.NUM_GRP_SEP : numGrpSep;
    symbol = symbol ?? '';
    rightPosition = rightPosition ?? false;

    let numberStr = `${value}`;
    const m = /(\d+)(?:(\.\d+)|)/.exec(numberStr);
    if (!m) {
      return rightPosition ? numberStr + symbol : symbol + numberStr;
    }

    /*
    just read from left to right!
    first figure out, how many digits the first group might have and use modulo operator for that section
    after that section add the first grouping separator and start behind that section for every 3 digits and one group separator.
    then add the decimal separator and fix the decimals by the wanted precision

    substr and substring will work the same in that case!
     */
    const x = m[1].length > 3 ? m[1].length % 3 : 0;
    /* eslint-disable @typescript-eslint/restrict-plus-operands */
    numberStr =
      // preserve minus sign
      (value < 0 ? '-' : '') +
      (x ? m[1].substr(0, x) + numGrpSep : '') +
      m[1].substr(x).replace(/(\d{3})(?=\d)/g, `$1${numGrpSep}`) +
      (precision ? decSep + (+m[2] || 0).toFixed(precision).substr(2) : '');
    return rightPosition ? numberStr + symbol : symbol + numberStr;
    /* eslint-enable @typescript-eslint/restrict-plus-operands */
  }

  public static isFloat(floatStr: string): boolean {
    if (floatStr.length === 0) {
      return true;
    }
    if (NumberUtils.unformatNumber(floatStr)) {
      return true;
    }
    // NaN is false
    return false;
  }

  public static unformatNumberNoParse(value: string, numGrpSep?: string, decSep?: string): string {
    decSep = decSep ?? NumberUtils.DEC_SEP;
    numGrpSep = numGrpSep ?? NumberUtils.NUM_GRP_SEP;
    let numberStr: string = isEmpty(value) ? '' : value.toString();
    if (numberStr.length > 0) {
      numberStr = numberStr.replace(new RegExp(`\\${numGrpSep}`, 'g'), '');
      numberStr = numberStr.replace(decSep, '.');

      // Need to strip out the currency symbols from the start.
      NumberUtils.SYMBOLS.forEach((symbol) => {
        numberStr = numberStr.replace(symbol, '');
      });
      return numberStr;
    }
    return '';
  }

  /**
   *
   * @param value unscharfer Wert
   * @param decSep dezimaltrennzeichen
   * @param numGrpSep zahlentrennzeichen
   * @param precision Angabe der Nachkommastellen
   */
  public static unformatNumber(
    value: string,
    decSep?: string,
    numGrpSep?: string,
    precision?: number,
  ): number {
    value = NumberUtils.unformatNumberNoParse(value, numGrpSep, decSep);
    const numericValue = NumberUtils.createFloat(value);
    if (isNaN(numericValue) || value.toString().length === 0) {
      return NaN;
    }
    return precision ? NumberUtils.round(numericValue, precision) : numericValue;
  }

  /**
   * round any numeric value by precision (floating values)
   * @param value unscharfer wert
   * @param precision Angabe der Nachkommastellen
   * @returns gerundeter Wert
   */
  public static round(value: number, precision?: number): number {
    if (!value) {
      return 0;
    }
    if (!precision) {
      precision = 0;
    }
    // increase base
    const shift = Math.pow(10, precision);
    // multiply by base to get fixed numbers and then divide it by base to cure floating commas
    return Math.round(value * shift) / shift;
  }

  /**
   * A bitwise or operator can be used to truncate floating point values, works for positives as well as negatives
   */
  public static float2int(value: number): number {
    return float2int(value);
  }
}
