import {Pipe, PipeTransform} from '@angular/core';
import {DateUtils} from "../utils/date-utils";
import {getCurrentUser, isEmptyString} from "../utils/utils";

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
  public transform(value: Date, format?: string, timezone?: string, locale?: string): string {
    if (isEmptyString(format)) {
      const user = getCurrentUser();
      format = `${user.user_default_dateformat} ${user.user_default_timeformat}`;
      if (isEmptyString(format)) {
        format = DateUtils.DEFAULT_DATE_TIME_FORMAT;
      }
    }
    return DateUtils.formatDate(value, format);
  }
}
