import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatSnackBar} from "@angular/material/snack-bar";
import {debounceTime, distinctUntilChanged, fromEvent} from "rxjs";
import {BaseDetailComponent} from "../detail/base-detail.component";
import {BaseEditComponent} from "../edit/base-edit.component";
import {BaseDeleteComponent} from "../delete/base-delete.component";
import {MatMenuTrigger} from "@angular/material/menu";
import {SelectionModel} from "@angular/cdk/collections";
import {
  ApiService,
  Bean,
  BeanDataSource,
  DeviceService, LanguageService,
  UnsubscribeOnDestroyDirectiveAdapter
} from "@hrm-pwa/hrm-pwa-api";
import {SortDirection} from "@angular/material/sort/sort-direction";
import {ComponentType} from "@angular/cdk/portal";

@Component({
  selector: "base-list",
  templateUrl: "./base-list.component.html",
  styleUrls: ["./base-list.component.sass"],
})
export class BaseListComponent<T extends Bean> extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit {
  public displayedColumns: string[] = [
    "select",
    "actions",
    "name",
    "date_entered",
    "description",
  ];
  public defaultSort = 'name';
  public defaultSortDirection: SortDirection = 'asc';
  public total = 0;

  public dataSource: BeanDataSource<T> | null;
  public selection = new SelectionModel<T>(true, []);
  public detailComponent: ComponentType<BaseDetailComponent<T>> = BaseDetailComponent;
  public editComponent: ComponentType<BaseEditComponent<T>> = BaseEditComponent;
  public deleteComponent: ComponentType<BaseDeleteComponent<T>> = BaseDeleteComponent;

  constructor(
    public httpClient: HttpClient,
    public dialog: MatDialog,
    public beanService: ApiService<T>,
    protected snackBar: MatSnackBar,
    protected deviceService: DeviceService,
    protected languageService: LanguageService,
  ) {
    super();
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild("filter", {static: true}) filter: ElementRef;
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = {x: "0px", y: "0px"};

  public ngOnInit(): void {
    this.reduceColumns();
    this.loadData();
    this.subs.sink = fromEvent(this.filter.nativeElement, "keyup").pipe(
      distinctUntilChanged(),
      debounceTime(300),
    ).subscribe(
      () => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      }
    );
  }

  public reset(): void {
    this.filter.nativeElement.value = '';
    this.loadData();
  }

  public addNew(): void {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(this.editComponent, {
      data: {
        action: "add",
      },
      direction: tempDirection,
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((record: T) => {
      if (record) {
        // use the stored record of the internal list by all back-end updated properties
        record = this.beanService.getRecord(record.id);
        this.refreshTable();
        this.showNotification(
          "snackbar-success",
          this.languageService.getText('MAIN.ADD_RECORD_SUCCESSFULLY'),
          "bottom",
          "center"
        );
        this.detailCall(record);
      }
    });
  }

  public detailCall(record: T): void {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    this.dialog.open(this.detailComponent, {
      data: {
        record,
        action: "detail",
      },
      direction: tempDirection,
    });
  }

  public editCall(record: T): void {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(this.editComponent, {
      data: {
        record,
        action: "edit",
      },
      direction: tempDirection,
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((record: T) => {
      if (record) {
        this.refreshTable();
        this.showNotification(
          "black",
          this.languageService.getText('MAIN.EDIT_RECORD_SUCCESSFULLY'),
          "bottom",
          "center"
        );
      }
    });
  }

  public deleteItem(record: T): void {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(this.deleteComponent, {
      width: '400px',
      data: record,
      direction: tempDirection,
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        this.refreshTable();
        this.showNotification(
          "snackbar-danger",
          this.languageService.getText('MAIN.DELETE_RECORD_SUCCESSFULLY'),
          "bottom",
          "center"
        );
      }
    });
  }

  protected reduceColumns(): void {
    if (this.deviceService.isMobile()) {
      this.displayedColumns = this.displayedColumns.slice(0, 4);
    } else if (this.deviceService.isTablet()) {
      this.displayedColumns = this.displayedColumns.slice(0, 8);
    }
  }

  protected refreshTable(): void {
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.renderedData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.renderedData.forEach((row) =>
        this.selection.select(row)
      );
  }

  public removeSelectedRows(): void {
    const totalSelect = this.selection.selected.length;
    this.selection.selected.forEach((item) => {
      const index: number = this.dataSource.renderedData.findIndex(
        (d) => d === item
      );
      // console.log(this.dataSource.renderedData.findIndex((d) => d === item));
      this.beanService.records.splice(index, 1);
      this.refreshTable();
      this.selection = new SelectionModel<T>(true, []);
    });
    this.showNotification(
      "snackbar-danger",
      this.languageService.getText('MAIN.DELETE_RECORD_SUCCESSFULLY'),
      "bottom",
      "center"
    );
  }

  public loadData(): void {
    this.dataSource = new BeanDataSource(
      this.beanService,
      this.paginator,
      this.sort,
    );
    this.dataSource.loadData(this.displayedColumns);
    this.subs.sink = this.dataSource.totalCount.subscribe((total: number) => {
        setTimeout(() => {
          this.total = total;
        });
      }
    );
  }

  public showNotification(colorName, text, placementFrom, placementAlign): void {
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  // context menu
  public onContextMenu(event: MouseEvent, item: T): void {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menuData = {item: item};
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }
}
