import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from './sub-sink';
import {User} from "../../models/models";
import {getCurrentUser} from "../utils";

/**
 * A class that automatically unsubscribes all observables when the object gets destroyed
 */
@Injectable()
export class UnsubscribeOnDestroyServiceAdapter implements OnDestroy {
  protected readonly currentUser: User;
  /**
   * The subscription sink object that stores all subscriptions
   */
  public subs = new SubSink();

  constructor() {
    this.currentUser = getCurrentUser();
  }

  /**
   * The lifecycle hook that unsubscribes all subscriptions when the component / object gets destroyed
   */
  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
