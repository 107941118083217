import {Inject, Injectable} from "@angular/core";
import {BehaviorSubject, Observable, of, switchMap, tap, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {UnsubscribeOnDestroyServiceAdapter} from '../utils/adapters/UnsubscribeOnDestroyServiceAdapter';
import {BearerToken, User} from "../models/models";
import {ApiService} from "./api.service";
import {getCurrentUser, isEmptyString} from "../utils/utils";
import {Role} from "../models/enums";
import {Config, CONFIG_TOKEN} from "../config";

@Injectable({
  providedIn: "root",
})
export class AuthService extends UnsubscribeOnDestroyServiceAdapter {
  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    @Inject(CONFIG_TOKEN) protected config_token: Config,
    private apiService: ApiService<User>,
  ) {
    super();
    this.currentUserSubject = new BehaviorSubject<User>(getCurrentUser());
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public login(username: string, password: string): Observable<BearerToken> {
    return this.apiService.authenticateUser(username, password).pipe(
      tap((user) => {
        if (!user) {
          throw new Error('AUTHENTICATION.INVALID_LOGIN_ERROR');
        }
      }),
      switchMap((user) => {
        localStorage.setItem("currentUser", JSON.stringify(user));
        this.currentUserSubject.next(user);
        return this.apiService.loginClient(username, password).pipe();
      }),
      catchError((err) => {
        this.logout();
        return throwError(err);
      }),
    );
  }

  public setupACL(): void {
    const user = this.currentUserValue;
    if (user.role !== Role.Admin && user.role !== Role.All) {
      this.subs.sink = this.apiService.loadACLByUser(user).pipe()
        .subscribe((summary) => {
          user.acl = summary;
          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
        });
    }
  }

  public logout(): void {
    const user = getCurrentUser();
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem('access_token');
    this.currentUserSubject.next(null);
    of(!isEmptyString(user?.token)).subscribe((loggedOut) => {
      if (loggedOut) {
        // keep this call to new window base href to be sure, all root services gets destroid and don't keep data of previous user session
        // do not use "this.router.navigate(["/authentication/signin"])"
        window.location.href = this.config_token.logoutUrl ?? '/';
      }
    });
  }
}
