import {Component, OnInit, Input} from "@angular/core";
import {DeviceService, UnsubscribeOnDestroyDirectiveAdapter} from "@hrm-pwa/hrm-pwa-api";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.sass"],
})
export class BreadcrumbComponent extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit {
  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;

  public homeLink = '';
  public isMobile = false;

  constructor(
    private deviceService: DeviceService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    if (this.currentUser.id) {
      this.homeLink = "/employee/dashboard";
    } else {
      this.homeLink = "/authentication/signin";
    }
  }
}
