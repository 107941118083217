import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {ACLInterAction, Bean, canDo, isEmptyString, UnsubscribeOnDestroyDirectiveAdapter} from "@hrm-pwa/hrm-pwa-api";

@Component({
  selector: 'acl-refresh-button',
  templateUrl: './acl-refresh-button.component.html',
  styleUrls: ['./acl-refresh-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AclRefreshButtonComponent<T extends Bean> extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit {
  private _m = '';

  @Input()
  public set module(module: string) {
    this._m = module;
    this.checkInteraction();
  }

  private _disabled = false;
  @Input()
  public set disabled(disabled: boolean) {
    this.cls = disabled ? `${this.cls} col-grey` : `${this.cls.replace('col-grey', '')}`;
    this.cls = this.cls.trim();
    this._disabled = disabled;
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  private _r: T = null;

  @Input()
  public set record(record: T) {
    this._r = record;
    this.checkInteraction();
  }

  @Input() public raised = false;
  @Input() public cls = '';
  @Input() public tooltip = 'MAIN.REFRESH';
  @Output() public clicked: EventEmitter<boolean> = new EventEmitter();
  public readonly action = ACLInterAction.LIST;
  private _allow = false;

  public get display(): boolean {
    return this._allow;
  }

  constructor(
    protected changeDetector: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.raised && !isEmptyString(this.cls)) {
      this.cls = '';
    }
    this.checkInteraction();
  }

  private checkInteraction(): void {
    this._allow = canDo(this.action, this._m, this._r);
    this.changeDetector.detectChanges();
  }

  public interact(): void {
    this.clicked.emit(true);
  }
}
