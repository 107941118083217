<div class="container">
  <!--<div mat-dialog-content>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
        {{noEmployeeMsg}}
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
        <div class="example-button-row">

        </div>
      </div>
    </div>
  </div>-->
  <mat-card class="mb-5">
    <mat-card-title-group>
      <mat-card-title>
        <mat-icon color="warn">info</mat-icon>
        {{'USERS.NO_EMPLOYEE_SET' | translate}}
      </mat-card-title>
    </mat-card-title-group>
    <mat-card-content>
      {{noEmployeeMsg}}
    </mat-card-content>
    <mat-divider inset></mat-divider>
    <mat-card-actions class="example-button-row m-l-10">
      <button mat-raised-button color="primary" (click)="close()" tabindex="-1">{{'MAIN.OK' | translate}}</button>
      <button mat-raised-button color="warn" (click)="logout()"
              tabindex="-1">{{'DASHBOARD.LOGOUT' | translate}}</button>
    </mat-card-actions>
  </mat-card>
</div>
