import {Injectable} from "@angular/core";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {Subject} from "rxjs";
import {LanguageService} from "@hrm-pwa/hrm-pwa-api";

@Injectable({
  providedIn: 'root',
})
export class CustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  public firstPageLabel: string;
  public itemsPerPageLabel: string;
  public lastPageLabel: string;
  public nextPageLabel: string;
  public previousPageLabel: string;

  constructor(protected languageService: LanguageService) {
    this.previousPageLabel = this.languageService.getText('MAIN.PAGINATOR.PREVIOUS_PAGE');
    this.nextPageLabel = this.languageService.getText('MAIN.PAGINATOR.NEXT_PAGE');
    this.firstPageLabel = this.languageService.getText('MAIN.PAGINATOR.FIRST_PAGE');
    this.lastPageLabel = this.languageService.getText('MAIN.PAGINATOR.LAST_PAGE');
    this.itemsPerPageLabel = this.languageService.getText('MAIN.PAGINATOR.ITEMS_PER_PAGE');
  }

  public getRangeLabel(page: number, pageSize: number, total: number): string {
    if (!total) {
      return `${this.languageService.getText('MAIN.PAGINATOR.PAGE')} 1 ${this.languageService.getText('MAIN.PAGINATOR.OF')} 1`;
    }
    const amountPages = Math.ceil(total / (pageSize ? pageSize : 1));
    const from = page * pageSize + 1;
    let to = (page + 1) * pageSize;
    if (to > total) {
      to = total;
    }
    return `${from} - ${to} ${this.languageService.getText('MAIN.PAGINATOR.OF')} ${total}`;
  }
}
