import {KeyValue} from "../../models/models";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete/autocomplete";
import {FormGroup} from "@angular/forms";
import {enumBlankReplacer} from "../../models/enums";
import {isEmptyString} from "../utils";

/**
 * filters by the given value into reduced display options for form fields such as select or auto-complete
 */
export const filterOptions = (value: string, options: unknown[]): string[] | KeyValue[] | unknown[] => {
  value = value.toLowerCase();
  if (!Array.isArray(options) || !options.length) {
    return [];
  }
  if (typeof options[0] === 'string') {
    return options.filter(option => option.toLowerCase().includes(value));
  }
  if ('key' in options[0] && 'value' in options[0]) {
    return options.filter(option => option.value.toLowerCase().includes(value));
  }
  if ('id' in options[0] && 'name' in options[0]) {
    return options.filter(option => option.name.toLowerCase().includes(value));
  }
  return [];
};

export const relateSelectionChanged = (event: MatAutocompleteSelectedEvent, field: string, form: FormGroup): void => {
  form.get(field)?.setValue(event.option.value);
  form.get(`${field}_id`)?.setValue(event.option.id);
  form.get(`${field.replace('_name', '')}_id`)?.setValue(event.option.id);
};

export const enumReplacingEncode = (value: unknown): string => {
  if (isEmptyString(value)) {
    return '';
  }
  return (value as string).replace(/\s/g, enumBlankReplacer);
};

export const enumReplacingDecode = (value: unknown): string => {
  if (isEmptyString(value)) {
    return '';
  }
  return (value as string).replace(new RegExp(enumBlankReplacer, 'g'), ' ');
};
