<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 ms-2 me-2 font-17">
            {{dialogTitle}}
            <button mat-button (click)="openAll()">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <button mat-button (click)="closeAll()">
              <mat-icon>arrow_drop_up</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <form *ngIf="beanForm && !recordLocked; else loading" class="register-form m-4" [formGroup]="beanForm" (ngSubmit)="submit">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
          <div class="example-button-row">
            <acl-save-button [disabled]="!beanForm.valid || !checkInteraction()"
                             (clicked)="confirm()"></acl-save-button>
            <cancel-button (clicked)="cancel()"></cancel-button>
          </div>
        </div>
      </div>
      <mat-accordion multi>
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'MAIN.BASE_PANEL' | translate}}
            </mat-panel-title>
            <mat-panel-description class="right-aligned">
              <mat-icon>info</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="row">
            <div class=" col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{'MAIN.NAME' | translate}}</mat-label>
                <input matInput formControlName="name"/>
                <mat-error *ngIf="beanForm.get('name').hasError('required')">
                  Name required
                </mat-error>
              </mat-form-field>
            </div>
            <mat-action-row>
              <button mat-button (click)="nextStep()">
                <mat-icon>arrow_right</mat-icon>
              </button>
            </mat-action-row>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'MAIN.DESCRIPTION_PANEL' | translate}}
            </mat-panel-title>
            <mat-panel-description class="right-aligned">
              <mat-icon>description</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="row">
            <div class=" col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{'MAIN.DESCRIPTION' | translate}}</mat-label>
                <textarea matInput formControlName="description"></textarea>
              </mat-form-field>
            </div>
            <mat-action-row>
              <button mat-button (click)="prevStep()">
                <mat-icon>arrow_left</mat-icon>
              </button>
            </mat-action-row>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
          <div class="example-button-row">
            <acl-save-button [disabled]="!beanForm.valid || !checkInteraction()"
                             (clicked)="confirm()"></acl-save-button>
            <cancel-button (clicked)="cancel()"></cancel-button>
          </div>
        </div>
      </div>
    </form>
    <ng-template #loading>
      <mat-spinner [diameter]="30" style="margin:0 auto;"></mat-spinner>
    </ng-template>
  </div>
</div>
