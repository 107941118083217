import {BehaviorSubject, delay, merge, Observable, map} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {isEmptyString} from "../utils/utils";
import {Bean} from "../models/models";
import {ApiService} from "./api.service";
import {BaseDataSource} from "./base-data-source";
import {Injectable} from "@angular/core";

@Injectable()
export class BeanDataSource<T extends Bean> extends BaseDataSource<T> {

  private columns: string[];
  public totalCount: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
    public service: ApiService<T>,
    paginator: MatPaginator,
    _sort: MatSort,
  ) {
    super(paginator, _sort);
  }

  public set displayedColumns(displayedColumns: string[]) {
    this.columns = displayedColumns;
  }

  public get displayedColumns(): string[] {
    return this.columns;
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  public connect(): Observable<T[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.service.data,
      this._sort.sortChange,
      this.filterChange,
      this.paginator.page,
    ];
    return merge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this.service.records
          .slice()
          .filter((record: T) => record.unionSortString?.indexOf(this.filter.toLowerCase()) !== -1);
        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());
        // Grab the page's slice of the filtered sorted data.
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        this.renderedData = sortedData.splice(
          startIndex,
          this.paginator.pageSize
        );
        this.totalCount.next(this.filteredData.length);
        return this.renderedData;
      }),
      // dirty hack to prevent expression has changed on rendered material table with empty rows
      delay(100),
    );
  }

  public disconnect(): void {
  }

  /** Returns a sorted copy of the database data. */
  public sortData(data: T[]): T[] {
    if (!this._sort.active || isEmptyString(this._sort.direction)) {
      return data;
    }
    return data.sort((a, b): number => {
      let propertyA: number | string | Date = "";
      let propertyB: number | string | Date = "";
      const column = this.columns.find(c => c === this._sort.active);
      if (isEmptyString(column)) {
        return 0;
      }
      [propertyA, propertyB] = [a[column], b[column]];
      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
      return (
        (valueA < valueB ? -1 : 1) * (this._sort.direction === "asc" ? 1 : -1)
      );
    });
  }

  public loadData(displayedColumns: string[]): void {
    this.columns = displayedColumns;
    this.subs.sink = this.service.fetch(this.columns).pipe().subscribe();
  }
}
