<div class="container">
  <h3 mat-dialog-title>{{'MAIN.DELETE_DIALOG_TITLE' | translate}}</h3>
  <div mat-dialog-content>
    <div class="row">
      <!--Gift Icon-->
      <div class="col text-center"><i class="far fa-times-circle fa-6x col-red"></i></div>
      <!--Modal Text-->
      <div class="col-8">
        <dl class="dl-horizontal">
          <dt class="pull-left me-2">{{'MAIN.NAME' | translate}}:</dt>
          <dd>{{ record.name }}</dd>
          <dt class="pull-left me-2">{{'MAIN.DESCRIPTION' | translate}}:</dt>
          <dd>{{ record.description }}</dd>
        </dl>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end" class="mb-2">
    <button mat-flat-button color="warn" [mat-dialog-close]="1" [disabled]="!allow"
            (click)="confirmDelete()">
      {{'MAIN.DELETE' | translate}}
    </button>
    <button mat-flat-button (click)="onNoClick()" tabindex="-1">{{'MAIN.CANCEL' | translate}}</button>
  </mat-dialog-actions>
</div>
