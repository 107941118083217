import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from "../services/language.service";

/**
 * use this pipe for direct translation of loaded language files.
 * do not rely on "translate" pipe of TranslateModule by @ngx-translate/core => it uses an observable and return the value before observable completed
 * languages get loaded by LanguageService at "APP_INITIALIZER" hook
 */
@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  public transform(key: string): string {
    return this.languageService.getText(key)
  }
}
