import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators,} from "@angular/forms";
import {
  ACLInterAction,
  ApiService,
  AttachedFile,
  Bean,
  canDo,
  isEmptyString, LanguageService,
  relateSelectionChanged,
  UnsubscribeOnDestroyDirectiveAdapter
} from "@hrm-pwa/hrm-pwa-api";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete/autocomplete";
import {MatAccordion} from "@angular/material/expansion";

@Component({
  selector: "base-edit",
  templateUrl: "./base-edit.component.html",
  styleUrls: ["./base-edit.component.sass"],
})
export class BaseEditComponent<T extends Bean> extends UnsubscribeOnDestroyDirectiveAdapter implements OnInit {
  public dialogTitle: string;
  public beanForm: FormGroup;
  public step = 0;
  public allExpanded = false;
  public recordLocked = false;
  protected action: string;
  protected _record: T;
  protected files: AttachedFile[] = [];

  public set record(record: T) {
    this._record = record;
  }

  public get record(): T {
    return this._record;
  }

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    public dialogRef: MatDialogRef<BaseEditComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public beanService: ApiService<T>,
    protected fb: FormBuilder,
    protected languageService: LanguageService,
  ) {
    super();
    // Set the defaults
    this.action = data.action;
    if (this.action === "edit") {
      this.dialogTitle = this.languageService.getText('MAIN.EDIT_TITLE');
      this.record = data.record;
    } else {
      this.dialogTitle = this.languageService.getText('MAIN.EDIT_TITLE');
      this.record = {} as T;
    }
  }

  public openAll() {
    this.allExpanded = true;
    this.accordion.openAll();
  }

  public closeAll() {
    this.allExpanded = false;
    this.accordion.closeAll();
  }

  public setStep(index: number) {
    if (!this.allExpanded) {
      this.step = index;
    }
  }

  public nextStep() {
    if (!this.allExpanded) {
      this.step++;
    }
  }

  public prevStep() {
    if (!this.allExpanded) {
      this.step--;
    }
  }

  public uploadedFile(file: Promise<AttachedFile>, fileField: string): void {
    file.then((f) => {
      if (!isEmptyString(fileField)) {
        f.name = fileField;
        this.files.push(f);
        this.beanForm.get(fileField)?.patchValue(f.filename);
      }
    });
  }

  public ngOnInit(): void {
    this.createForm();
  }

  public checkInteraction(): boolean {
    return canDo(ACLInterAction.EDIT, this.beanService.module, this.record);
  }

  public relateSelectionChanged(event: MatAutocompleteSelectedEvent, field: string): void {
    relateSelectionChanged(event, field, this.beanForm);
  }

  protected createForm(): void {
    this.beanForm = this.fb.group({
      id: [this._record.id],
      name: [this._record.name, [Validators.required]],
      description: [this._record.description],
    });
  }

  public lockRecord(locked: boolean): void{
    this.recordLocked = locked;
  }

  public submit(): void {
    this.lockRecord(true);
    this.confirm();
  }

  public cancel(): void {
    this.dialogRef.close(0);
  }

  public confirm(record?: T): void {
    record = record ?? this.beanForm.getRawValue();
    if (isEmptyString(record.id)) {
      this.subs.sink = this.beanService.addRecord(record).subscribe((r) => {
        this.dialogRef.close(record);
        this.lockRecord(false);
      });
    } else {
      this.subs.sink = this.beanService.updateRecord(record).subscribe((r) => {
        this.dialogRef.close(record);
        this.lockRecord(false);
      });
    }
  }
}
