import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class RightSidebarService {
  private sidebarSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sidebarState = this.sidebarSubject.asObservable();

  public setRightSidebar = (value: boolean): void => {
    this.sidebarSubject.next(value);
  };

  constructor() {
  }
}
