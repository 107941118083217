import {DataSource} from "@angular/cdk/collections";
import {BehaviorSubject} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {Injectable, OnDestroy} from "@angular/core";
import {SubSink} from '../utils/adapters/sub-sink';

@Injectable()
export abstract class BaseDataSource<T> extends DataSource<T> implements OnDestroy {
  protected filterChange = new BehaviorSubject("");

  public get filter(): string {
    return this.filterChange.value;
  }

  public set filter(filter: string) {
    this.filterChange.next(filter);
  }

  public filteredData: T[] = [];
  public renderedData: T[] = [];

  constructor(
    public paginator: MatPaginator,
    public _sort: MatSort,
  ) {
    super();
    // Reset to the first page when the user changes the filter.
    this.filterChange.subscribe(() => (this.paginator.pageIndex = 0));
  }

  /**
   * The subscription sink object that stores all subscriptions
   */
  public subs = new SubSink();

  /**
   * The lifecycle hook that unsubscribes all subscriptions when the component / object gets destroyed
   */
  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
