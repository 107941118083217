import {Directive, OnDestroy} from '@angular/core';
import {SubSink} from './sub-sink';
import * as enums from "../../models/enums";
import {User} from "../../models/models";
import {getCurrentUser} from "../utils";

/**
 * A class that automatically unsubscribes all observables when the object gets destroyed
 */
@Directive()
export class UnsubscribeOnDestroyDirectiveAdapter implements OnDestroy {
  public readonly currentUser: User;
  /**
   * hacky but comfy way to forward all enums al public references into templates
   * component must derive from this base directive
   */
  public enums = enums;
  /**
   * The subscription sink object that stores all subscriptions
   */
  public subs = new SubSink();

  constructor() {
    this.currentUser = getCurrentUser();
  }

  /**
   * The lifecycle hook that unsubscribes all subscriptions when the component / object gets destroyed
   */
  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
